import { useState, useEffect } from "react";
import DataExplorerLeftPanel from "./LeftPanel/DataExplorerLeftPanel";
import DataExplorerRightPanel from "./RightPanel/DataExplorerRightPanel";
import { Button, Col, Row, Card } from "reactstrap";
import { LeftPanelType } from "./LeftPanel/leftPanelType";
import AIWizardFlyout from "./AIWizardFlyout/AIWizardFlyout";
import { QueryTabsContext } from "./RightPanel/Tabs/QueryTabsContext";
import { IQueryTab } from "./RightPanel/Tabs/useQueryTabs";
import {
  updateDataExplorerQueryTabTitleCounter,
  updateDataExplorerSelectedTab,
  updateDataExplorerTabs,
  updateDataExplorerTabTitleCounter,
} from "../../redux/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getConnections } from "../../api/Connections/getConnections";
import { useQuery } from "@tanstack/react-query";
import Page500 from "../auth/Page500";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";
import { getWorkspacesForCurrentUser } from "src/api/Workspaces/getWorkspacesForCurrentUser";
import { getDerivedViews } from "src/api/DerivedViews/getDerivedViews";
import { getSavedQueries } from "src/api/SavedQueries/getSavedQueries";

const DataExplorer = () => {
  const dataExplorer = useAppSelector((state) => state.dataExplorerTabs);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const subscription = useAppSelector((state) => state.subscription);

  const [sidebarView, setSidebarView] = useState<LeftPanelType>(
    LeftPanelType.Connections,
  );
  const [aiExplorerVisible, setAiExplorerVisible] = useState<boolean>(false);
  const [tabs, setTabs] = useState<IQueryTab[]>(dataExplorer.tabs);
  const [selectedTab, setSelectedTab] = useState<number>(
    dataExplorer.selectedTab,
  );
  const [queryTabTitleCounter, setQueryTabTitleCounter] = useState(
    dataExplorer.queryTabTitleCounter,
  );
  const [queryBuilderTabTitleCounter, setQueryBuilderTabTitleCounter] =
    useState(dataExplorer.queryBuilderTabTitleCounter);

  const header = { "Connect-Cloud-Client": "CDataDataExplorer" };

  useEffect(() => {
    dispatch(updateDataExplorerTabs(tabs));
  }, [tabs]); // eslint-disable-line

  const { data: connectionResponse, error: getConnectionsError } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () =>
      getConnections({
        userType: user.role,
        userId: user.id,
        header: header,
      }),
    meta: {
      errorMessage:
        "Failed to get user defined connection list due to the following error:",
    },
  });
  const connectionsList = connectionResponse?.connections ?? [];

  const {
    data: derivedViewsResponse,
    error: getDerivedViewsError,
    refetch: refetchDerivedViews,
    isLoading: isLoadingDerivedViews,
    isRefetching: isRefetchingDerivedViews,
  } = useQuery({
    queryKey: ["/account/derivedViews"],
    queryFn: () => getDerivedViews(),
    meta: {
      errorMessage: "Failed to get derived views due to the following error:",
    },
  });
  const derivedViews = derivedViewsResponse?.derivedViews ?? [];
  derivedViews.forEach(
    (derivedView: any) =>
      (derivedView.accountId = derivedViewsResponse?.accountId),
  );

  const {
    data: savedQueriesResponse,
    error: getSavedQueriesError,
    refetch: refetchSavedQueries,
    isLoading: isLoadingSavedQueries,
    isRefetching: isRefetchingSavedQueries,
  } = useQuery({
    queryKey: ["/users/self/savedQueries"],
    queryFn: () => getSavedQueries(),
    meta: {
      errorMessage: "Failed to get saved queries due to the following error:",
    },
  });
  const savedQueries = savedQueriesResponse?.savedQueries ?? [];

  const {
    data: workspacesResponse,
    error: getWorkspacesError,
    refetch: refetchWorkspaces,
  } = useQuery({
    queryKey: ["/workspaces/forCurrentUser"],
    queryFn: () => getWorkspacesForCurrentUser(),
    meta: {
      errorMessage: "Failed to get workspaces due to the following error:",
    },
  });
  const workspaces = workspacesResponse?.workspaces ?? [];

  if (
    getConnectionsError ||
    getDerivedViewsError ||
    getSavedQueriesError ||
    getWorkspacesError
  ) {
    const error =
      getConnectionsError ||
      getDerivedViewsError ||
      getSavedQueriesError ||
      getWorkspacesError;
    return <Page500 error={error} />;
  }

  return (
    <div id="data-explorer-container" className="h-100 data-explorer">
      <QueryTabsContext.Provider
        value={{
          tabs: tabs,
          setTabs: setTabs,
          selectedTab: selectedTab,
          setSelectedTab: (newSelectedTab) => {
            setSelectedTab(newSelectedTab);
            dispatch(updateDataExplorerSelectedTab(newSelectedTab));
          },
          queryTabTitleCounter: queryTabTitleCounter,
          setQueryTabTitleCounter: (newCounter) => {
            setQueryTabTitleCounter(newCounter);
            dispatch(updateDataExplorerTabTitleCounter(newCounter));
          },
          queryBuilderTabTitleCounter: queryBuilderTabTitleCounter,
          setQueryBuilderTabTitleCounter: (newCounter) => {
            setQueryBuilderTabTitleCounter(newCounter);
            dispatch(updateDataExplorerQueryTabTitleCounter(newCounter));
          },
        }}
      >
        <Row>
          <Col>
            <h1 className="h3 ms-1 mb-4">Data Explorer</h1>
          </Col>
          {subscription.plan?.id !== BillingPlanIds.Standard2025 && (
            <Col className="float-end-ai-btn ps-2" hidden={aiExplorerVisible}>
              <Button
                data-testid="button-open-ai-generator"
                color="primary"
                className="button-open-ai-generator"
                onClick={() => {
                  setAiExplorerVisible(true);
                }}
              >
                <span style={{ marginRight: "8px" }}>
                  <i className="fa fa-solid fa-sparkles align-self-center" />
                </span>
                AI Generator
              </Button>
            </Col>
          )}
          <AIWizardFlyout
            isVisible={aiExplorerVisible}
            closeWizard={() => setAiExplorerVisible(false)}
          />
        </Row>
        <Card className="mb-0" id="data-explorer-card">
          <Row className="h-100 ms-0 ps-0">
            <DataExplorerLeftPanel
              connectionsList={connectionsList}
              derivedViews={derivedViews}
              savedQueries={savedQueries}
              workspaces={workspaces}
              fetchDerivedViews={refetchDerivedViews}
              fetchSavedQueries={refetchSavedQueries}
              fetchWorkspaces={refetchWorkspaces}
              derivedViewsLoading={
                isLoadingDerivedViews || isRefetchingDerivedViews
              }
              savedQueriesLoading={
                isLoadingSavedQueries || isRefetchingSavedQueries
              }
              sidebarView={sidebarView}
              setSidebarView={setSidebarView}
            />
            <DataExplorerRightPanel
              connectionsList={connectionsList}
              setSidebarView={setSidebarView}
              fetchDerivedViews={refetchDerivedViews}
              fetchSavedQueries={refetchSavedQueries}
            />
          </Row>
        </Card>
      </QueryTabsContext.Provider>
    </div>
  );
};

export default DataExplorer;
