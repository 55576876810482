import { DataType, IQueryResult } from "src/models";
import { cdataFetch } from "../cdataFetch";
import { IServiceError } from "src/models/IServiceError";

export type IColumnMetadata = {
  catalog: string;
  schema: string;
  tableName: string;
  columnName: string;
  dataType: DataType;
  dataTypeName: string;
  isPrimary: boolean;
  isNullable: boolean;
};

export async function getColumnsForTable(params: {
  connectionName: string;
  schema: string;
  tableName: string;
  workspace?: string;
  headers?: Record<string, string>;
}) {
  const { connectionName, schema, tableName, workspace, headers } = params;
  const workspaceParam = workspace
    ? `&workspace=${encodeURIComponent(workspace ?? "")}`
    : "";
  const rawData = await cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/columns?catalogName=${encodeURIComponent(connectionName)}&schemaName=${encodeURIComponent(schema)}&tableName=${encodeURIComponent(tableName)}${workspaceParam ?? ""}`,
    headers: headers,
  });

  if ("error" in rawData) {
    throw new Error(
      rawData.error?.message ??
        `An error ocurred loading the column metadata for connection = ${connectionName}, schema = ${schema}, table = ${tableName}`,
    );
  }

  const metadata: IColumnMetadata[] =
    "results" in rawData && rawData?.results?.[0]?.rows != null
      ? rawData.results[0].rows
          .map<IColumnMetadata>((r) => {
            return {
              catalog: r[0],
              schema: r[1],
              tableName: r[2],
              columnName: r[3],
              dataType: r[4],
              dataTypeName: r[5],
              isPrimary: r[11],
              isNullable: r[12],
            };
          })
          .sort((a, b) => a.columnName.localeCompare(b.columnName))
      : [];

  return metadata;
}
