import { cdataFetchReturnHeaders } from "src/api/cdataFetch";
import { IDerivedView, IPartialDerivedView } from "src/models";

export async function createDerivedView(body: IPartialDerivedView) {
  return cdataFetchReturnHeaders<IDerivedView>({
    method: "POST",
    url: "/account/derivedViews",
    body: body,
  });
}
