import classNames from "classnames";
import "./CDataIconCircle.scss";

interface CDataIconCircleProps extends React.HTMLAttributes<HTMLDivElement> {}

export function CDataIconCircle(props: CDataIconCircleProps) {
  const { className, ...remainingProps } = props;

  const classes = classNames("cdata-icon-circle", className);

  return (
    <div {...remainingProps} className={classes}>
      {props.children}
    </div>
  );
}
