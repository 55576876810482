import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { validateQuery } from "../../validation/validateQuery";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { QueryTabType } from "../Tabs/queryTabType";
import { useDuplicateColumnFinder } from "./useDuplicateColumnFinder";
import { useMutation } from "@tanstack/react-query";
import { createDerivedView } from "../../api/createDerivedView";
import { useState } from "react";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { dataExplorerConstants } from "../../DataExplorer.constants";

export interface CreateDerivedViewModalProps {
  tab: IQueryTab;
  displayed: boolean;
  close: () => void;
  fetchDerivedViews: () => void;
  setSidebarView: (newSideBarView: LeftPanelType) => void;
}

export function CreateDerivedViewModal(props: CreateDerivedViewModalProps) {
  const tabs = useQueryTabs();
  const { findDuplicateColumns } = useDuplicateColumnFinder();

  const { tab, displayed, close, fetchDerivedViews, setSidebarView } = props;

  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync: createDerivedViewAsync } = useMutation({
    mutationKey: ["/account/derivedViews/create"],
    mutationFn: createDerivedView,
    meta: {
      errorMessage: "Failed to get column metadata due to the following error:",
    },
    onSuccess: (data, variables) => {
      setSidebarView(LeftPanelType.DerivedViews);
      fetchDerivedViews();
      ToastrSuccess(
        "Derived View successfully created",
        `${variables.name} was successfully created.`,
      );
      close();
      tabs.ChangeTabType(
        tabs.CurrentTabId,
        variables.name ?? "",
        QueryTabType.EditDerivedView,
        data.data.id ?? "",
        tab.queryString ?? "",
      );
    },
  });

  const onSubmit = async (values: any) => {
    setIsProcessing(true);
    const currentQueryInput = tab.queryString ?? "";

    //validation for query input string
    const validation = validateQuery(currentQueryInput);
    if (!validation.isValid) {
      ToastrError(
        "Failed to create derived view due to the following error: ",
        validation.message,
      );
      setIsProcessing(false);
      return;
    }

    //verify duplicate column names before actually saving the dervied view.
    const { duplicateColumns, queryId, invalidQuery } =
      await findDuplicateColumns(currentQueryInput);

    if (invalidQuery) {
      //if /query call throws error, then don't proceed to save the derived view.
      close();
      return;
    }

    if (duplicateColumns?.length > 0) {
      const duplicateColumnNames = duplicateColumns.join(", ");
      ToastrError(
        "Duplicate column names detected",
        `Unable to save derived view due to an unresolved SQL compilation error. Ambiguous column names: ${duplicateColumnNames}.`,
        `Query ID: ${queryId}`,
      );

      close();
      return;
    }

    await createDerivedViewAsync({ ...values, Query: currentQueryInput });
    setIsProcessing(false);
  };

  const initialFormValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Derived View name must be between 2 and 128 characters")
      .max(128, "Derived View name must be between 2 and 128 characters")
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_ -]{0,126}[a-zA-Z0-9_-]$/,
        "Derived View name must be 2-128 ASCII letters, numbers, hyphens, underscores, and spaces; must begin and end with a letter/underscore; and cannot end with a space",
      )
      .required("This is a required field"),
  });

  return (
    <CDataModalV2
      title="Save as Derived View"
      close={close}
      displayed={displayed}
      spacedFooter={false}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Primary}
          type="submit"
          form="newDerivedViewForm"
        >
          Create
        </CDataButton>
      }
      secondaryButton={
        <CDataButton buttonType={ButtonType.Secondary} onClick={() => close()}>
          Cancel
        </CDataButton>
      }
    >
      <>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values: any) => onSubmit(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="newDerivedViewForm" onSubmit={handleSubmit}>
              <CDataTypography
                className="mb-2"
                color="typography-color-text-muted"
                variant="typography-variant-body-medium"
              >
                {dataExplorerConstants.DERIVED_VIEW_INFO}
              </CDataTypography>
              <Form.Group>
                <Form.Label className="required h5">View Name</Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  placeholder="Enter a name for your derived view."
                  value={values.name}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
        <div hidden={!isProcessing}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
      </>
    </CDataModalV2>
  );
}
