import { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { CDataTextField } from "../../../../../../../components/form/CDataTextField";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import { IQueryFilterProps } from "../../../types/IQueryFilterProps";
import { isDataTypeNumeric } from "src/utility/metadata/isDataTypeNumeric";

export function FilterValue(props: IQueryFilterProps) {
  const {
    queryFilter,
    setQueryFilter,
    setIsConfirmButtonDisabled,
    isDisabled,
  } = props;

  const isFieldNumeric = isDataTypeNumeric(queryFilter.column.dataType);

  const [isTouched, setIsTouched] = useState(false);

  const value = (queryFilter.data.value as string) ?? "";

  let errorMessage = "";
  if (!value || value === "") {
    errorMessage = "Filter value must not be empty.";
  }

  if (isFieldNumeric && isNaN(Number(value))) {
    errorMessage = "Provide a numeric value for this SQL operator type.";
  }

  useEffect(() => {
    if (errorMessage) {
      setIsConfirmButtonDisabled(true);
    } else {
      setIsConfirmButtonDisabled(false);
    }
  }, [errorMessage]); // eslint-disable-line

  return (
    <Col className="ps-0">
      <CDataTextField
        type="text"
        aria-label="Filter Value"
        value={queryFilter.data.value ?? ""}
        isInvalid={isTouched && Boolean(errorMessage)}
        onChange={(e) => {
          setQueryFilter({
            ...queryFilter,
            data: {
              value: e.target.value,
            },
          });
        }}
        onBlur={() => {
          setIsTouched(true);
        }}
        className="filter-value-combo-input"
        disabled={isDisabled}
      />
      {isTouched && errorMessage && (
        <CDataTypography
          variant="typography-variant-helper-text"
          color="typography-color-danger"
        >
          {errorMessage}
        </CDataTypography>
      )}
    </Col>
  );
}
