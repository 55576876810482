import { IAssetListItem } from "../../../../models";
import { IWorkspacesProps, WorkspaceSchema } from "./WorkspacesList";
import { useMutation } from "@tanstack/react-query";
import { getChildAssetFromWorkspace } from "src/api/Workspaces/getChildAssetFromWorkspace";
import { getChildAssetFromFolder } from "src/api/Workspaces/getChildAssetFromFolder";

export function useDataExplorerWorkspaces(
  initialData: WorkspaceSchema[],
  filteredData: WorkspaceSchema[],
  setInitialData: (data: WorkspaceSchema[]) => void,
  setFilteredData: (data: WorkspaceSchema[]) => void,
  props: IWorkspacesProps,
) {
  const { mutateAsync: getChildAssetFromWorkspaceAsync } = useMutation({
    mutationKey: ["/getChildAssetFromWorkspace"],
    mutationFn: getChildAssetFromWorkspace,
    meta: {
      errorMessage: "Error fetching child Assets from Workspace:",
    },
    onSuccess: (data, variables) => {
      if ((data.assets?.length ?? 0) > 0) {
        const workspaceId = variables;
        const assetList = pushAssets(data.assets!, workspaceId);

        const updatedWorkspaceTables: WorkspaceSchema[] = [];
        for (let index = 0; index < initialData.length; index++) {
          const currentWorkspace: WorkspaceSchema = initialData[index];
          const currentWorkspaceId = currentWorkspace.workspaceId;

          if (workspaceId === currentWorkspaceId) {
            updatedWorkspaceTables.push({
              workspaceId: currentWorkspaceId,
              name: currentWorkspace.name,
              subRows: assetList,
            });
          } else {
            updatedWorkspaceTables.push(currentWorkspace);
          }
        }

        setInitialData(updatedWorkspaceTables);
        if (props.searchQuery) {
          const updatedWorkspaceTables: WorkspaceSchema[] = [];
          for (let wIndex = 0; wIndex < filteredData.length; wIndex++) {
            const currentWorkspaceId = filteredData[wIndex].workspaceId;
            if (currentWorkspaceId === initialData[0].workspaceId) {
              updatedWorkspaceTables.push({
                workspaceId: currentWorkspaceId,
                name: filteredData[wIndex].name,
                subRows: initialData[0].subRows,
              });
            } else {
              updatedWorkspaceTables.push(filteredData[wIndex]);
            }
          }
        }
        setFilteredData(updatedWorkspaceTables);
      }
    },
  });

  const { mutateAsync: getChildAssetFromFolderAsync } = useMutation({
    mutationKey: ["/getChildAssetFromFolder"],
    mutationFn: getChildAssetFromFolder,
    meta: {
      errorMessage: "Error fetching child Assets from Folder:",
    },
    onSuccess: (data, variables) => {
      if ((data.assets?.length ?? 0) > 0) {
        const subAssetList = pushAssets(data.assets!, variables.workspaceId);

        const assetList: WorkspaceSchema[] = [];
        const updatedWorkspaceTables: WorkspaceSchema[] = [];

        for (let index = 0; index < initialData.length; index++) {
          const workspace: WorkspaceSchema = initialData[index];
          const currWorkspaceId = workspace.workspaceId;
          if (variables.workspaceId === currWorkspaceId) {
            for (
              let subIndex = 0;
              subIndex < workspace.subRows!.length;
              subIndex++
            ) {
              const asset = workspace.subRows![subIndex];
              const currAssetId = asset.assetId;

              if (variables.folderId === currAssetId) {
                assetList.push({
                  assetId: currAssetId,
                  name: asset.name,
                  assetType: asset.assetType,
                  childCount: asset.childCount,
                  driver: asset.driver,
                  workspaceId: variables.workspaceId,
                  subRows: subAssetList,
                  dataAssetCategory: asset.dataAssetCategory,
                });
              } else {
                assetList.push(asset);
              }
            }
            updatedWorkspaceTables.push({
              workspaceId: currWorkspaceId,
              name: workspace.name,
              subRows: assetList,
            });
          } else {
            updatedWorkspaceTables.push(workspace);
          }
        }
        setInitialData(updatedWorkspaceTables);

        if (props.searchQuery) {
          const assetList: WorkspaceSchema[] = [];
          const updatedWorkspaceTables: WorkspaceSchema[] = [];
          for (let wIndex = 0; wIndex < filteredData.length; wIndex++) {
            const currentWorkspace: WorkspaceSchema = filteredData[wIndex];
            if (currentWorkspace.workspaceId === initialData[0].workspaceId) {
              for (
                let subIndex = 0;
                subIndex < currentWorkspace.subRows!.length;
                subIndex++
              ) {
                const asset: WorkspaceSchema =
                  currentWorkspace.subRows![subIndex];
                const currAssetId = asset.assetId;

                if (initialData[0].subRows![0].assetId === currAssetId) {
                  assetList.push({
                    assetId: currAssetId,
                    name: asset.name,
                    assetType: asset.assetType,
                    childCount: asset.childCount,
                    driver: asset.driver,
                    workspaceId: currentWorkspace.workspaceId,
                    subRows: initialData[0].subRows![0].subRows,
                    dataAssetCategory: asset.dataAssetCategory,
                  });
                } else {
                  assetList.push(asset);
                }
              }
              updatedWorkspaceTables.push({
                workspaceId: currentWorkspace.workspaceId,
                name: currentWorkspace.name,
                subRows: assetList,
              });
            } else {
              updatedWorkspaceTables.push(currentWorkspace);
            }
          }
        }
        setFilteredData(updatedWorkspaceTables);
      }
    },
  });

  const functionsAndAPIs = {
    fetchChildAssetFromWorkspace: getChildAssetFromWorkspaceAsync,
    fetchChildAssetFromFolder: getChildAssetFromFolderAsync,
  };

  return functionsAndAPIs;
}

function pushAssets(
  assets: IAssetListItem[],
  workspaceId: string,
): WorkspaceSchema[] {
  const assetList: WorkspaceSchema[] = [];

  assets.forEach((asset) => {
    assetList.push({
      assetId: asset.id,
      name: asset.alias!,
      assetType: asset.assetType,
      childCount: asset.childCount ?? 0,
      workspaceId: workspaceId,
      driver: asset.driver,
      dataAssetCategory: asset.dataAssetCategory,
    });
  });

  return assetList;
}
