import { IQueryResult } from "src/models";
import { IServiceError } from "src/models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export async function getTables(params: {
  connection: string;
  schema: string;
}) {
  return cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/tables?catalogName=${encodeURIComponent(params.connection)}&schemaName=${encodeURIComponent(params.schema)}`,
  });
}
