import { useMutation } from "@tanstack/react-query";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { updateScheduledQuerySQL } from "../../../jobs/api/updateScheduledQuerySQL";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { Spinner } from "reactstrap";

interface IUpdateScheduledQueryModal {
  tab: IQueryTab;
  displayed: boolean;
  close: () => void;
}

export const UpdateScheduledQueryModal = (
  props: IUpdateScheduledQueryModal,
) => {
  const tabs = useQueryTabs();

  const { tab, displayed, close } = props;

  const { mutateAsync: updateScheduledQuery, isPending } = useMutation({
    mutationKey: ["/scheduledquery", tab.uniqueId],
    mutationFn: updateScheduledQuerySQL,
    onSuccess: () => {
      ToastrSuccess(
        "Scheduled Query successfully updated",
        `You have successfully updated the SQL query for Scheduled Query ${tab.tabName}.`,
      ),
        tabs.SetUnsavedChanges(tabs.CurrentTabId, false);
    },
    onError: (error) => {
      ToastrError(
        "Failed to update Scheduled Query",
        `Changes to the SQL query for your Scheduled Query, ${tab.tabName}, could not be saved.`,
        error.message,
      );
    },
    onSettled: () => close(),
  });

  if (!displayed) return null;

  return (
    <>
      <CDataModalV2
        title="Save Changes"
        displayed={displayed}
        close={close}
        displayToggleCloseButton
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={() =>
              updateScheduledQuery({
                queryId: tab.uniqueId!,
                queryText: tab.queryString.trim(),
              })
            }
          >
            Save
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={() => close()}
          >
            Cancel
          </CDataButton>
        }
      >
        <CDataTypography>
          You are about to overwrite {tab.tabName} with new changes. Are you
          sure you want to proceed?
        </CDataTypography>
      </CDataModalV2>
      <div hidden={!isPending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
};
