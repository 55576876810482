import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrSuccess } from "../../../../services/toastrService";
import { Spinner } from "reactstrap";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { useMutation } from "@tanstack/react-query";
import { ISavedQuery } from "src/models/SavedQueries/ISavedQuery";
import { updateSavedQuery } from "../../api/updateSavedQuery";

export type UpdateSavedQueryModalProps = {
  tab: IQueryTab;
  displayed: boolean;
  close: () => void;
  fetchSavedQueries: () => void;
};

export function UpdateSavedQueryModal(props: UpdateSavedQueryModalProps) {
  const tabs = useQueryTabs();

  const { tab, displayed, close, fetchSavedQueries } = props;

  const {
    mutateAsync: updateSavedQueryAsync,
    isPending: isUpdateSavedQueryPending,
  } = useMutation({
    mutationKey: ["/account/derivedViews/create"],
    mutationFn: ({ id, body }: { id: string; body: ISavedQuery }) =>
      updateSavedQuery({ id, body }),
    meta: {
      errorMessage: "Failed to get column metadata due to the following error:",
    },
    onSuccess: () => {
      fetchSavedQueries();
      ToastrSuccess(
        "Saved Query successfully saved",
        `${tab.tabName} was successfully updated.`,
      );
      close();
      tabs.SetUnsavedChanges(tabs.CurrentTabId, false);
    },
  });

  const onSubmit = async () => {
    const trimmedQueryInput = tab.queryString.trim();
    const values = {
      query: trimmedQueryInput,
    };

    await updateSavedQueryAsync({ id: tab.uniqueId ?? "", body: values });
  };

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Save Changes"
        spacedFooter={false}
        primaryButton={
          <CDataButton buttonType={ButtonType.Primary} onClick={onSubmit}>
            Save
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={close}
            data-testid="button-cancel"
          >
            Cancel
          </CDataButton>
        }
      >
        You are about to overwrite {tab.tabName} with new changes. Are you sure
        you want to proceed?
      </CDataModalV2>
      <div hidden={!isUpdateSavedQueryPending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
