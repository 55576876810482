import { useField } from "formik";
import { CDataSelect, CDataSelectProps } from "../select/CDataSelect";

interface CDataFormSelect extends Omit<CDataSelectProps, "name" | "value"> {
  name: string;
  errorMessage?: string; // Only necessary if you need to manually control the displayed errorMessage
}

export function CDataFormSelect(props: CDataFormSelect) {
  const { errorMessage, loading, ...selectProps } = props;

  const [field, meta] = useField(props.name);
  let helperText = props.helperText;
  if (meta.error && meta.touched) {
    helperText = meta.error;
  } else if (errorMessage) {
    helperText = errorMessage;
  }

  return (
    <CDataSelect
      {...selectProps}
      loading={loading}
      name={props.name}
      value={loading ? null : field.value}
      isInvalid={Boolean(meta.touched && meta.error) || props.isInvalid}
      helperText={helperText}
      onBlur={(event) => {
        field.onBlur(event);
        if (props.onBlur != null) {
          props.onBlur(event);
        }
      }}
      onChange={(event, child) => {
        field.onChange(event);
        if (props.onChange != null) {
          props.onChange(event, child);
        }
      }}
    />
  );
}
