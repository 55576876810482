import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getAllCacheJobs } from "../../../api/CacheJobs/getAllCacheJobs";
import { ICacheJobListItem } from "../../../models/Cache/ICacheJobListItem";

export type ITableToCacheJobMap = {
  isLoading: boolean;
  getCacheJobForTable: (params: {
    connectionName: string;
    schemaName: string;
    tableName: string;
  }) => ICacheJobListItem | null;
};

/** Returns a mapping of each table that is cached to the cache job.  */
export function useTableToCacheJobMap(): ITableToCacheJobMap {
  // Load all the users cache jobs in the background so we can show what
  // tables are being cached.
  // We don't really care if this fails since it's only used to display a chip in the UI.
  const { data, isLoading } = useQuery({
    queryKey: ["/cacheJobs/list"],
    queryFn: () => getAllCacheJobs(),
  });

  const cacheJobLookup: Map<string, ICacheJobListItem> = useMemo(() => {
    return data == null
      ? new Map()
      : new Map(
          (data.list ?? []).map((cacheJob) => [
            `[${cacheJob.sourceConnectionName}].[${cacheJob.sourceSchema}].[${cacheJob.sourceTable}]`,
            cacheJob,
          ]),
        );
  }, [data]);

  function getCacheJobForTable(params: {
    connectionName: string;
    schemaName: string;
    tableName: string;
  }) {
    return (
      cacheJobLookup.get(
        `[${params.connectionName}].[${params.schemaName}].[${params.tableName}]`,
      ) ?? null
    );
  }

  return {
    isLoading,
    getCacheJobForTable,
  };
}
