import { cdataFetchReturnHeaders } from "src/api/cdataFetch";
import { ISavedQuery } from "src/models/SavedQueries/ISavedQuery";

export async function updateSavedQuery(params: {
  id: string;
  body: ISavedQuery;
}) {
  return cdataFetchReturnHeaders<ISavedQuery>({
    method: "PUT",
    url: `/users/self/savedQueries/${encodeURIComponent(params.id)}`,
    body: params.body,
  });
}
