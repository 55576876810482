import { cdataFetch } from "src/api/cdataFetch";
import { RenameSavedQueryModalFormValues } from "../LeftPanel/SavedQueries/RenameSavedQueryModal";

export async function renameSavedQuery(
  values: RenameSavedQueryModalFormValues,
) {
  return cdataFetch<void>({
    method: "PUT",
    url: `/users/self/savedQueries/${encodeURIComponent(values.id ?? "")}`,
    body: values,
  });
}
