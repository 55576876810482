import { Menu, MenuProps } from "@mui/material";
import classNames from "classnames";
import "./CDataMenu.scss";

export interface CDataMenuProps extends MenuProps {}

export function CDataMenu(props: CDataMenuProps) {
  const { className, children, ...remainingProps } = props;

  const classes = classNames("cdata-menu", className);

  return (
    <Menu {...remainingProps} className={classes}>
      {children}
    </Menu>
  );
}
