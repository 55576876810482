import { isDataTypeNumeric } from "src/utility/metadata/isDataTypeNumeric";
import { DataType } from "../../../../../models";
import { IQueryBuilderColumn } from "../models/IQueryBuilderColumn";
import { FilterType, IQueryBuilderFilter } from "../models/IQueryBuilderFilter";
import {
  IQueryBuilderModel,
  IQueryBuilderJoin,
  QueryJoinType,
  QueryAggregateFunctionType,
  IQueryBuilderSort,
} from "../models/IQueryBuilderModel";
import { QueryBuilderFilterMethod } from "../models/QueryBuilderFilterMethod";
import { FilterTypeString } from "../tabs/Builder/Filters/FilterOperator";
import { getFullyQualifiedColumnName } from "./getFullyQualifiedColumnName";
import { getFullyQualifiedTableName } from "./getFullyQualifiedTableName";

const actualJoinTypes: Record<QueryJoinType, string> = {
  [QueryJoinType.InnerJoin]: "INNER JOIN",
  [QueryJoinType.RightJoin]: "RIGHT JOIN",
  [QueryJoinType.LeftJoin]: "LEFT JOIN",
  [QueryJoinType.FullOuterJoin]: "FULL JOIN",
};

enum UppercaseSortDirection {
  "asc" = "ASC",
  "desc" = "DESC",
}

export function generateQueryBuilderSql(queryData: IQueryBuilderModel): string {
  // Create initial string
  const queryType = `SELECT ${getColumnSQL(queryData)}FROM`;

  // Append table name
  const fullyQualifiedTable = `${getFullyQualifiedTableName(queryData.from)} AS [${queryData.from.tableAlias}]`;

  // Stitch it all together
  const query = `${queryType} ${fullyQualifiedTable}${getJoinSQL(queryData)}${getFilterSQL(queryData)}${groupByToSQL(queryData)}${getSortSQL(queryData)}`;

  return query;
}

function getColumnSQL(queryData: IQueryBuilderModel): string {
  if (queryData.groupBy.length === 0 && queryData.metrics.length === 0) {
    return "* ";
  }

  // We always return any columns the user grouped by in the SQL.
  let groupByColumns = queryData.groupBy.map((g) =>
    getFullyQualifiedColumnName(g.column),
  );

  groupByColumns = [...groupByColumns, ...getMetricSQL(queryData)];

  return "\n" + groupByColumns.join(",\n") + "\n";
}

function getJoinSQL(queryData: IQueryBuilderModel): string {
  if (queryData.joins.length === 0) {
    return "";
  }
  // Append joins
  const joins = queryData.joins.map((j) => parseJoin(j)).join("\n");

  return "\n" + joins;
}

function getFilterSQL(queryData: IQueryBuilderModel): string {
  if (queryData.filters.length === 0) {
    return "";
  }
  // Append filters
  const filters = queryData.filters
    .map((j, index) => parseFilter(j, queryData.filterMethod, index))
    .join("\n");

  return "\n" + filters;
}

function groupByToSQL(queryData: IQueryBuilderModel): string {
  if (queryData.groupBy.length === 0 || queryData.metrics.length === 0) {
    return "";
  }

  const columnSql = queryData.groupBy
    .map((g) => getFullyQualifiedColumnName(g.column))
    .join(",\n");

  return "\nGROUP BY" + "\n" + columnSql + "\n";
}

function getSortSQL(queryData: IQueryBuilderModel): string {
  if (queryData.sort.length === 0) {
    return "";
  }
  // Append filters
  const sorts = queryData.sort.map((j, index) => parseSort(j, index)).join("");

  return "\n" + sorts;
}

function parseJoin(join: IQueryBuilderJoin) {
  const { joinType, left, right } = join;

  const joinText = actualJoinTypes[joinType] || "";

  return (
    `${joinText} ${getFullyQualifiedTableName(right.table)} AS [${right.table.tableAlias}] ` +
    `ON ${getFullyQualifiedColumnName(left)} ` +
    `= ${getFullyQualifiedColumnName(right)}`
  );
}

function parseFilter(
  filter: IQueryBuilderFilter,
  filterMethod: QueryBuilderFilterMethod,
  index: number,
) {
  const { column, filterType, data } = filter;

  let firstWord = "WHERE";
  if (index !== 0) {
    firstWord =
      filterMethod === QueryBuilderFilterMethod.AND ? "  AND" : "  OR";
  }

  const filterStrings: Record<FilterType | FilterTypeString, () => string> = {
    [FilterType.Between]: () => getFilterForBetween(column, data, firstWord),
    [FilterType.EqualTo]: () =>
      generateGenericFilterString(column, data, firstWord, "="),
    [FilterType.GreaterThan]: () =>
      generateGenericFilterString(column, data, firstWord, ">"),
    [FilterType.LessThan]: () =>
      generateGenericFilterString(column, data, firstWord, "<"),
    [FilterType.GreaterThanOrEqualTo]: () =>
      generateGenericFilterString(column, data, firstWord, ">="),
    [FilterType.LessThanOrEqualTo]: () =>
      generateGenericFilterString(column, data, firstWord, "<="),
    [FilterType.NotEqualTo]: () =>
      generateGenericFilterString(column, data, firstWord, "!="),
    [FilterTypeString.Contains]: () =>
      getFilterForContains(column, data, firstWord),
  };

  return filterStrings[filterType]();
}

const getFilterForContains = (
  column: IQueryBuilderColumn,
  data: Record<string, unknown>,
  firstWord: string,
) => {
  const sanitizedValue = isDataTypeNumeric(column.dataType)
    ? data.value
    : sanitizeValue(data.value as string);

  return `${firstWord} CONTAINS(${getFullyQualifiedColumnName(column)}, '${sanitizedValue}')`;
};

const getFilterForBetween = (
  column: IQueryBuilderColumn,
  data: Record<string, unknown>,
  firstWord: string,
) => {
  let firstSanitizedValue: string | number = isDataTypeNumeric(column.dataType)
    ? (data.start as number)
    : sanitizeValue(data.start as string);
  let secondSanitizedValue: string | number = isDataTypeNumeric(column.dataType)
    ? (data.end as number)
    : sanitizeValue(data.end as string);

  if (
    column.dataType === DataType.TIMESTAMP ||
    column.dataType === DataType.DATE
  ) {
    firstSanitizedValue = `'${firstSanitizedValue}'`;
    secondSanitizedValue = `'${secondSanitizedValue}'`;
  }

  return (
    `${firstWord} (${getFullyQualifiedColumnName(column)} >= ${firstSanitizedValue} ` +
    `AND ${getFullyQualifiedColumnName(column)} <= ${secondSanitizedValue})`
  );
};

const generateGenericFilterString = (
  column: IQueryBuilderColumn,
  data: Record<string, unknown>,
  firstWord: string,
  operator: string,
) => {
  if (isDataTypeNumeric(column.dataType)) {
    return `${firstWord} ${getFullyQualifiedColumnName(column)} ${operator} ${data.value}`;
  } else {
    const sanitizedValue = sanitizeValue(data.value as string);

    return `${firstWord} ${getFullyQualifiedColumnName(column)} ${operator} '${sanitizedValue}'`;
  }
};

const sanitizeValue = (value: string) => {
  return value.replace(/'/g, "''");
};

function getMetricSQL(queryData: IQueryBuilderModel): string[] {
  const sql: string[] = [];

  for (const metric of queryData.metrics) {
    const metricTypeToFunction: Record<QueryAggregateFunctionType, string> = {
      [QueryAggregateFunctionType.AVERAGE]: `AVG(${getFullyQualifiedColumnName(metric.column)})`,
      [QueryAggregateFunctionType.COUNT]: `COUNT(${getFullyQualifiedColumnName(metric.column)})`,
      [QueryAggregateFunctionType.DISTINCT_COUNT]: `COUNT(DISTINCT ${getFullyQualifiedColumnName(metric.column)})`,
      [QueryAggregateFunctionType.MAX]: `MAX(${getFullyQualifiedColumnName(metric.column)})`,
      [QueryAggregateFunctionType.MIN]: `MIN(${getFullyQualifiedColumnName(metric.column)})`,
      [QueryAggregateFunctionType.SUM]: `SUM(${getFullyQualifiedColumnName(metric.column)})`,
    };

    const metricSql = `${metricTypeToFunction[metric.operatorType]} AS [${metric.column.alias}]`;

    sql.push(metricSql);
  }

  return sql;
}
function parseSort(sort: IQueryBuilderSort, index: number) {
  const firstWord = index === 0 ? "ORDER BY" : ", ";
  const tableName = sort.column.alias
    ? `[${sort.column.alias}]`
    : getFullyQualifiedColumnName(sort.column);
  return `${firstWord} ${tableName} ${UppercaseSortDirection[sort.direction]}`;
}
