import { IConnectionList, UserRole } from "src/models";
import { cdataFetch } from "../cdataFetch";

export async function getConnections(params: {
  userType: UserRole;
  userId: string;
  header?: Record<string, string>;
}) {
  const { userType, userId, header } = params;
  const url =
    userType === UserRole.Query
      ? `/account/connections/forUser/${userId}`
      : "/account/connections";

  return cdataFetch<IConnectionList>({
    method: "GET",
    headers: header,
    url: url,
  });
}
