import { LeftPanelType } from "./leftPanelType";

export type IDataExplorerPanelMetadata = {
  name: string;
  icon: string | undefined;
  noData: string;
  searchLabel: string;
  view: LeftPanelType;
  isQueryCategory: boolean;
  prodVisible: boolean;
  standard2025Visible: boolean;
};

export const DataExplorerPanelMetadata: IDataExplorerPanelMetadata[] = [
  {
    name: "Workspaces",
    icon: "fa-folder-open",
    noData:
      "Go to the Datasets area of the application to setup your first Workspace.",
    searchLabel: "Search...",
    view: LeftPanelType.Workspaces,
    isQueryCategory: true,
    prodVisible: true,
    standard2025Visible: true,
  },
  {
    name: "Connections",
    icon: undefined,
    noData: "No connections found.",
    searchLabel: "Search connections...",
    view: LeftPanelType.Connections,
    isQueryCategory: false,
    prodVisible: true,
    standard2025Visible: true,
  },
  {
    name: "Schemas",
    icon: undefined,
    noData: "No schemas found for the selected connection.",
    searchLabel: "Search tables and views...",
    view: LeftPanelType.Schemas,
    isQueryCategory: false,
    prodVisible: true,
    standard2025Visible: true,
  },
  {
    name: "Saved Queries",
    icon: "fa-folder",
    noData: "Saved queries will appear here",
    searchLabel: "Search queries...",
    view: LeftPanelType.SavedQueries,
    isQueryCategory: true,
    prodVisible: true,
    standard2025Visible: true,
  },
  {
    name: "Derived Views",
    icon: "fa-border-none",
    noData: "Derived views will appear here",
    searchLabel: "Search views...",
    view: LeftPanelType.DerivedViews,
    isQueryCategory: true,
    prodVisible: true,
    standard2025Visible: false,
  },
];
