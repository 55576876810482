/* eslint-disable max-lines */
import { useState, useEffect, useContext, useRef } from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql.js";
import "codemirror/addon/selection/active-line";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { ColumnDef, Row, Table } from "@tanstack/react-table";
import useQueryTabs, { IQueryTab } from "./Tabs/useQueryTabs";
import { ToastrClean, ToastrError } from "../../../services/toastrService";
import {
  BillingStatus,
  FeatureId,
  IConnection,
  IQueryResult,
} from "../../../models";
import { isFeatureAllowed } from "../../../utility/SubscriptionAddonsFactory";
import ResultsDetailsGridContainer from "./Results/ResultsDetailsGridContainer";
import { sanitizeQuery, sanitizeQueryProps } from "./QueryEditor/sanitizeQuery";
import { doesDerivedViewReferenceDerivedView } from "../validation/doesDerivedViewReferenceDerivedView";
import { QueryTabType } from "./Tabs/queryTabType";
import { QueryTabsContext } from "./Tabs/QueryTabsContext";
import { QueryErrorNotificationBanner } from "./QueryEditor/QueryErrorNotificationBanner";
import { useMutation } from "@tanstack/react-query";
import { postQuery } from "../api/postQuery";
import { HttpError } from "../../../api/cdataFetch";
import { IServiceError } from "../../../models/IServiceError";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import { useAppSelector } from "../../../redux/hooks";
import { QueryEditor } from "./QueryEditor/QueryEditor";
import { QueryEditorButtonBar } from "./QueryEditor/QueryEditorButtonBar";
import { QueryResultsView } from "./Results/QueryResultsView";
import { getDataTypeName } from "../getDataTypeName";
import { ClearQueryModal } from "./modals/ClearQueryModal";
import { getWorkspaceNameFromQuery } from "./getWorkspaceNamefromQuery";
import { DownloadCSVModal } from "./modals/DownloadCSVModal";
import { CachingPromptNotification } from "./QueryEditor/CachingPromptNotification";
import { ResultsSubtitle } from "./QueryEditor/ResultsSubtitle";
import { TimeoutModal } from "../../../components/modal/TimeoutModal";
import {
  getColumnsForTable,
  IColumnMetadata,
} from "src/api/metadata/getColumnsForTable";
import { UpdateScheduledQueryModal } from "./ScheduledQuery/UpdateScheduledQueryModal";
import { UpdateSavedQueryModal } from "./modals/UpdateSavedQueryModal";
import { UpdateDerivedViewModal } from "./modals/UpdateDerivedViewModal";
import { LeftPanelType } from "../LeftPanel/leftPanelType";

export interface IEditorAndResultsProps {
  tab: IQueryTab;
  abortControllerRef: React.MutableRefObject<AbortController | null>;
  abortRunningQueryRequest: () => void;
  connectionsList: IConnection[];
  setSidebarView: (sidebarView: LeftPanelType) => void;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
}

const EditorAndResults = (props: IEditorAndResultsProps) => {
  const tabContext = useContext(QueryTabsContext);
  const tabs = useQueryTabs();
  const subscription = useAppSelector((state) => state.subscription);
  const usage = useAppSelector((state) => state.usage);

  const { tab, abortControllerRef, abortRunningQueryRequest } = props;

  const [queryInput, setQueryInput] = useState<string>(tab.queryString);
  const [queryResults, setQueryResults] = useState<string>("");
  const [resultData, setResultData] = useState<any[]>([]);
  const [resultColumns, setResultColumns] = useState<ColumnDef<any>[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [resultsToggled, setResultsToggled] = useState<boolean>(false);
  const [queryLimit, setQueryLimit] = useState<number>(100);
  const [columnDetails, setColumnDetails] = useState<any>([]);
  const [columnMetadata, setColumnMetadata] = useState<
    IDataExplorerRightPanelColumnMetadata[]
  >([]);
  const [filteredColumns, setFilteredColumns] = useState<any[]>([]);
  const [areDerivedViewsAllowed, setAreDerivedViewsAllowed] =
    useState<boolean>(true);
  const [wasQuerySuccessful, setWasQuerySuccessful] = useState<boolean>(false);
  const [executeDisabled, setExecuteDisabled] = useState<boolean>(
    tab.queryString.length <= 0,
  );
  const [useCachingPrompt, setUseCachingPrompt] = useState(false);
  const [queryEditorHeight, setQueryEditorHeight] = useState(150);
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);
  const [csvDownloadError, setCsvDownloadError] = useState(false);
  const [updateDerivedViewModalOpen, setUpdateDerivedViewModalOpen] =
    useState(false);
  const [updateSavedQueryModalOpen, setUpdateSavedQueryModalOpen] =
    useState<boolean>(false);
  const [updateScheduledQueryModalOpen, setUpdateScheduledQueryModalOpen] =
    useState(false);
  const [downloadCSVModalOpen, setDownloadCSVModalOpen] =
    useState<boolean>(false);
  const [clearQueryModalOpen, setClearQueryModalOpen] =
    useState<boolean>(false);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(true);

  const tableRef = useRef<
    Table<IDataExplorerRightPanelColumnMetadata> | undefined
  >(undefined);
  const header = { "Connect-Cloud-Client": "CDataDataExplorer" };
  const flags = useFeatureFlags().getFlags(["dataexplorer_new_derived_views"]);

  const isNewDerivedViewUIResult =
    flags.dataexplorer_new_derived_views.enabled &&
    tab.connectionName === "CData" &&
    tab.schemaName === "DerivedViews";

  let timerCacheNotificationPrompt: number;

  const {
    mutateAsync: getColumnMetadataAsync,
    isPending: isColumnMetadataLoading,
  } = useMutation({
    mutationKey: ["/getColumnMetadata"],
    mutationFn: (workspace?: string) =>
      getColumnsForTable({
        connectionName: tab?.connectionName ?? "",
        schema: tab?.schemaName ?? "",
        tableName: tab?.tableName ?? "",
        workspace,
      }),
    meta: {
      errorMessage: "Failed to get column metadata due to the following error:",
    },
    onError: (error) => {
      if (error instanceof HttpError && error.statusCode === 401) {
        setTimeoutModalOpen(true);
      } else {
        errorCallback(error);
      }
    },
    onSuccess: (data) => {
      const colMetadata = data;
      const colArray: IDataExplorerRightPanelColumnMetadata[] = colMetadata.map(
        (column: IColumnMetadata) => {
          return {
            "Column Name": column.columnName,
            "Column Type": column.dataTypeName,
            Nullable: column.isNullable,
            IsPrimary: column.isPrimary,
          };
        },
      );
      setColumnMetadata(colArray);
      setShowResults(true);
      setResultColumns([]);
      setResultData([]);
      setQueryResults("");
    },
  });

  const { mutateAsync, isPending: isQueryLoading } = useMutation({
    mutationKey: ["/query"],
    mutationFn: postQuery,
    meta: {
      errorMessage: "Failed to execute query",
    },
    onSuccess: (data) => {
      processQuery(data);
      setExecuteDisabled(false);
    },
    onError: (error) => {
      if (error instanceof HttpError && error.statusCode === 401) {
        setTimeoutModalOpen(true);
      } else {
        setResultData([]);
        errorCallback(error.message, error.name === "AbortError");
      }

      setWasQuerySuccessful(false);
      setExecuteDisabled(false);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      await checkForAllowedFeatures();
      if (tab.metadataOnStart) await fetchColumnMetadata();
      if (tab.resultsOnStart) await runQueryAndShowResults();
    };
    fetchData();

    return () => {
      if (timerCacheNotificationPrompt != null) {
        clearTimeout(timerCacheNotificationPrompt);
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredColumns(columnMetadata);
  }, [columnMetadata, resultsToggled]);

  const fetchColumnMetadata = async () => {
    // Update metadata if table is derived view
    const workspace =
      tab.tabType === QueryTabType.Workspace ? tab.connectionName : "";

    setColumnMetadata([]);
    setColumnDetails(columnDetailsWithoutPrimary);
    setExecuteDisabled(true);
    await getColumnMetadataAsync(workspace);
    setExecuteDisabled(false);
  };

  const runQueryAndShowResults = async () => {
    setColumnMetadata([]);
    setResultsToggled(true);
    timerCacheNotificationPrompt = setTimeout(() => {
      setUseCachingPrompt(true);
    }, 120000);
    await executeQuery();
    clearTimeout(timerCacheNotificationPrompt);
  };

  // Move this down
  const checkForAllowedFeatures = () => {
    setAreDerivedViewsAllowed(
      isFeatureAllowed(
        subscription?.limits?.availableFeatureIds ?? [],
        FeatureId.DerivedViews,
      ),
    );
  };

  const errorCallback = (
    errorMsg: Error | string | { message: string } | null | undefined,
    isAborted?: boolean,
  ) => {
    if (!isAborted) {
      if (typeof errorMsg === "string") {
        setErrorMessage(errorMsg);
      } else if ("message" in errorMsg!) {
        // This handles the Error object case or an object with { message: string }
        setErrorMessage(errorMsg.message);
      } else {
        // This is just here to handle things like nulls
        setErrorMessage(errorMsg ?? "");
      }
    }

    tabs.SetTabStatus(tab.id, false, !isAborted);
  };

  const sortFunc = (
    rowA: Row<IDataExplorerRightPanelColumnMetadata>,
    rowB: Row<IDataExplorerRightPanelColumnMetadata>,
    columnId: keyof IDataExplorerRightPanelColumnMetadata,
  ) => {
    const a = rowA.original;
    const b = rowB.original;
    const isAsc = !!tableRef.current
      ?.getState()
      .sorting.find((column) => column.id === columnId)?.desc;

    const aIsPrimaryKey = a.IsPrimary;
    const bIsPrimaryKey = b.IsPrimary;

    //sort the fields and prioritize if its a primaryKey
    if (aIsPrimaryKey && !bIsPrimaryKey) {
      return isAsc ? 1 : -1;
    }

    if (!aIsPrimaryKey && bIsPrimaryKey) {
      return isAsc ? -1 : 1;
    }

    // Sort the fields based on their values
    const aValue = a[columnId] ?? "";
    const bValue = b[columnId] ?? "";

    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue);
    }

    if (aValue === bValue) return 0;

    return bValue > aValue ? -1 : 1;
  };

  const columnDetailsWithoutPrimary = [
    {
      accessorKey: "Column Name",
      id: "Column Name",
      enableSorting: true,
      sortingFn: sortFunc,
    },
    {
      accessorKey: "Column Type",
      id: "Column Type",
      enableSorting: true,
      sortingFn: sortFunc,
    },
    {
      accessorKey: "Nullable",
      id: "Nullable",
      enableSorting: true,
      sortingFn: sortFunc,
    },
  ];

  const sanitizeParameters: sanitizeQueryProps = {
    currentQueryInput: queryInput,
    queryLimit: queryLimit,
    setQueryLimit: setQueryLimit,
    setCurrentQueryInput: setQueryInput,
    enforceLimit: true,
  };

  const workspaceName = getWorkspaceNameFromQuery(tab.queryString);

  // Convert any periods in accessorKey to underscores to keep tanstack from trying to access properties that don't exist
  const convertAccessorKey = (key: string): string => {
    return key.includes(".") ? key.replaceAll(".", "_") : key;
  };

  const processQuery = (payload: IQueryResult | IServiceError) => {
    if (payload.error) {
      errorCallback(payload.error.message);
      setWasQuerySuccessful(false);
      return;
    } else {
      setWasQuerySuccessful(true);
    }

    if ("results" in payload && payload.results != null) {
      const schema = payload.results[0].schema ?? [];
      const rows = payload.results[0].rows ?? [];
      const fieldArray = schema.map((x) => ({
        accessorKey: convertAccessorKey(x.columnLabel!),
        id: x.columnLabel!,
        enableSorting: true,
      }));
      const allColumnNamesArray = fieldArray.map((x) => x.accessorKey);
      setResultColumns(fieldArray);

      const resultArray = [];
      for (let i = 0; i < rows.length; i++) {
        const row: any = {};
        for (let j = 0; j < allColumnNamesArray.length; j++) {
          row[allColumnNamesArray[j]] = rows[i][j];
        }

        resultArray.push(row);
      }
      const colArray = schema.map((el: any) => {
        return {
          "Column Name": el.columnLabel,
          "Column Type": getDataTypeName(el.dataType),
          Nullable: el.nullable,
        };
      });

      setColumnMetadata(colArray);
      setResultData(resultArray);
      setShowResults(true);

      // Set query text when successful so that the tab is no longer considered "empty"
      const newTabs = [...tabContext.tabs];
      const currentTabIndex = tabContext.tabs.findIndex((t) => t.id === tab.id);

      if (newTabs[currentTabIndex]) {
        newTabs[currentTabIndex].queryString = queryInput;
      }

      tabContext.setTabs(newTabs);
      tabs.SetTabStatus(tab.id, false, false);
      const rowsText = resultArray.length === 1 ? "row" : "rows";
      setQueryResults(
        `Query Complete (${resultArray.length} ${rowsText} processed)`,
      );
    } else {
      let affectedRows;
      if (
        "results" in payload &&
        payload.results != null &&
        payload.results.length > 0
      ) {
        affectedRows = payload.results[payload.results.length - 1].affectedRows;
      } else {
        affectedRows = 0;
      }
      setResultData([]);
      tabs.SetTabStatus(tab.id, false, false);
      setShowResults(false);
      setQueryResults(`Query Complete (${affectedRows} rows affected)`);
    }
  };

  const executeQuery = async () => {
    tabs.SetTabStatus(tab.id, true, false);

    ToastrClean();
    setResultData([]);
    setQueryResults("");
    setResultColumns([]);
    setColumnDetails(columnDetailsWithoutPrimary);
    setExecuteDisabled(true);

    const sanitizedQuery = sanitizeQuery(sanitizeParameters);

    abortControllerRef.current = new AbortController();
    setCancelButtonDisabled(false);
    try {
      await mutateAsync({
        isWorkspace: Boolean(tab.isWorkspace),
        workspaceName: workspaceName,
        header: header,
        body: {
          query: sanitizedQuery,
          defaultCatalog: tab.connectionName!,
        },
        abortSignal: abortControllerRef.current?.signal,
      });
    } catch (err) {
      console.error("An error occurred during mutation:", err);
    } finally {
      abortControllerRef.current = null;
      setCancelButtonDisabled(true);
    }
  };

  const handleRunClick = (e: any) => {
    e.preventDefault();
    runQueryAndShowResults();
  };

  const handleRefreshClickCallback = (e: any) => {
    e.preventDefault();
    (() => {
      setClearQueryModalOpen(!clearQueryModalOpen);
    })();
  };

  const clearQuery = () => {
    setQueryInput("");
    setExecuteDisabled(true);
    setQueryResults("");
    setResultColumns([]);
    setResultData([]);
    setColumnMetadata([]);
    (() => {
      setClearQueryModalOpen(!clearQueryModalOpen);
    })();
  };

  const noData = () => {
    return <div className="text-muted text-center">No matching records</div>;
  };

  const openSaveChangesModal = () => {
    if (tab.tabType === QueryTabType.DerivedView) {
      if (doesDerivedViewReferenceDerivedView(queryInput)) {
        ToastrError(
          "Unable to create a derived view",
          "Cannot create a derived view using a query from a previously created derived view." +
            " Please modify your query to use the original source data and try again.",
        );
      } else {
        setUpdateDerivedViewModalOpen(true);
      }
    } else if (tab.tabType === QueryTabType.SavedQuery) {
      setUpdateSavedQueryModalOpen(true);
    } else if (tab.tabType === QueryTabType.ScheduledQuery) {
      setUpdateScheduledQueryModalOpen(true);
    }
  };

  // Could be moved down
  const filterColumns = (event: any) => {
    let temp: { [key: string]: any }[] = columnMetadata;
    if (event.target.value !== "") {
      temp = temp.filter((ele: any) =>
        ele["Column Name"]
          .toLowerCase()
          .includes(event.target.value.toLowerCase()),
      );
    }
    setFilteredColumns(temp);
  };

  const resultsTable = (
    <ResultsDetailsGridContainer
      tab={tab}
      loading={isColumnMetadataLoading || isQueryLoading}
      showResults={showResults}
      resultsToggled={resultsToggled}
      resultColumns={resultColumns}
      resultData={resultData}
      noData={noData}
      columnDetails={columnDetails}
      columnMetadata={filteredColumns}
      queryEditorHeight={queryEditorHeight}
      abortRunningQueryRequest={abortRunningQueryRequest}
      tableRef={tableRef}
    />
  );

  const resultsSubtitle = (
    <ResultsSubtitle
      resultsToggled={resultsToggled}
      queryResults={queryResults}
      columnMetadata={columnMetadata}
      columnMetadataLoading={isColumnMetadataLoading}
      tab={tab}
    />
  );

  const cachingPromptNotification = (
    <CachingPromptNotification
      useCachingPrompt={useCachingPrompt}
      setUseCachingPrompt={setUseCachingPrompt}
    />
  );

  const trialOverage =
    usage.rowsOverLimit === true &&
    usage.billingStatus ===
      (BillingStatus.NewAccount ||
        BillingStatus.Trial ||
        BillingStatus.TrialEnding);

  const disableForOverages =
    usage.connectionsOverLimit === true ||
    usage.dataSourcesOverLimit === true ||
    trialOverage === true;

  return (
    <span className="EditorAndResults" hidden={!(tabs.CurrentTabId === tab.id)}>
      <QueryEditorButtonBar
        tab={tab}
        cancelButtonDisabled={cancelButtonDisabled}
        abortRunningQueryRequest={abortRunningQueryRequest}
        queryInput={queryInput}
        handleRunClick={handleRunClick}
        handleRefreshClickCallback={handleRefreshClickCallback}
        queryLimit={queryLimit}
        setQueryLimit={setQueryLimit}
        wasQuerySuccessful={wasQuerySuccessful}
        executeDisabled={executeDisabled}
        setExecuteDisabled={setExecuteDisabled}
        areDerivedViewsAllowed={areDerivedViewsAllowed}
        openSaveChangesModal={openSaveChangesModal}
        fetchDerivedViews={props.fetchDerivedViews}
        fetchSavedQueries={props.fetchSavedQueries}
        setSidebarView={props.setSidebarView}
      />
      <QueryErrorNotificationBanner errorMessage={errorMessage} />
      <div id="editorAndResultContainer">
        <QueryEditor
          tab={tab}
          currentQueryInput={queryInput}
          queryEditorDisabled={disableForOverages}
          rowLimitLockdown={trialOverage}
          setCurrentQueryInput={setQueryInput}
          setExecuteDisabled={setExecuteDisabled}
          queryEditorHeight={queryEditorHeight}
          setQueryEditorHeight={setQueryEditorHeight}
          connectionsList={props.connectionsList}
        />
        <QueryResultsView
          resultColumns={resultColumns}
          executeDisabled={executeDisabled}
          isNewDerivedViewUIResult={isNewDerivedViewUIResult}
          resultsToggled={resultsToggled}
          setResultsToggled={setResultsToggled}
          csvDownloadLoading={csvDownloadLoading}
          setDownloadCSVModalDisplayed={setDownloadCSVModalOpen}
          columnMetadata={columnMetadata}
          filterColumns={filterColumns}
          csvDownloadError={csvDownloadError}
          setCsvDownloadError={setCsvDownloadError}
          cachingPromptNotification={cachingPromptNotification}
          resultsTable={resultsTable}
          resultsSubtitle={resultsSubtitle}
        />
      </div>
      <div>
        <UpdateDerivedViewModal
          tab={tab}
          displayed={updateDerivedViewModalOpen}
          close={() => setUpdateDerivedViewModalOpen(false)}
          fetchDerivedViews={props.fetchDerivedViews}
        />
        <UpdateSavedQueryModal
          tab={tab}
          displayed={updateSavedQueryModalOpen}
          close={() => setUpdateSavedQueryModalOpen(false)}
          fetchSavedQueries={props.fetchSavedQueries}
        />
        <UpdateScheduledQueryModal
          tab={tab}
          displayed={updateScheduledQueryModalOpen}
          close={() => setUpdateScheduledQueryModalOpen(false)}
        />
        <DownloadCSVModal
          tab={tab}
          displayed={downloadCSVModalOpen}
          close={() => setDownloadCSVModalOpen(false)}
          queryInput={queryInput}
          setQueryInput={setQueryInput}
          queryLimit={queryLimit}
          setQueryLimit={setQueryLimit}
          csvDownloadLoading={csvDownloadLoading}
          setCSVDownloadLoading={setCsvDownloadLoading}
          setCsvDownloadError={setCsvDownloadError}
        />
        <ClearQueryModal
          displayed={clearQueryModalOpen}
          close={() => setClearQueryModalOpen(false)}
          clearQuery={clearQuery}
        />
        <TimeoutModal displayed={timeoutModalOpen} />
      </div>
    </span>
  );
};

export type IDataExplorerRightPanelColumnMetadata = {
  "Column Name": string;
  "Column Type": string;
  Nullable: boolean;
  IsPrimary?: boolean;
};

export default EditorAndResults;
