import { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { CDataTextField } from "../../../../../../../components/form/CDataTextField";
import { FilterType } from "../../../models/IQueryBuilderFilter";
import classnames from "classnames";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import { IQueryFilterProps } from "../../../types/IQueryFilterProps";
import { isDataTypeNumeric } from "src/utility/metadata/isDataTypeNumeric";

export function FilterRange(props: IQueryFilterProps) {
  const {
    queryFilter,
    setQueryFilter,
    setIsConfirmButtonDisabled,
    isDisabled,
  } = props;

  const [isStartRangeTouched, setIsStartRangeTouched] = useState(false);
  const [isEndRangeTouched, setIsEndRangeTouched] = useState(false);

  const isFieldNumericDataType = isDataTypeNumeric(queryFilter.column.dataType);
  const startRange = queryFilter.data.start as string;
  const endRange = queryFilter.data.end as string;

  const isStartRangeEmpty = !startRange || startRange === "";
  const isEndRangeEmpty = !endRange || endRange === "";
  const isStartRangeNumericAndInvalid =
    isFieldNumericDataType && isNaN(Number(startRange));
  const isEndRangeNumericAndInvalid =
    isFieldNumericDataType && isNaN(Number(endRange));
  const isRangeOutOfBounds =
    isFieldNumericDataType && Number(startRange) > Number(endRange);

  let startRangeErrorMessage;
  if (isStartRangeEmpty) {
    startRangeErrorMessage = "Filter value must not be empty.";
  } else if (isStartRangeNumericAndInvalid) {
    startRangeErrorMessage =
      "Provide a numeric value for this SQL operator type.";
  } else if (isRangeOutOfBounds) {
    startRangeErrorMessage = "Start Range must not exceed End Range";
  } else {
    startRangeErrorMessage = "";
  }

  let endRangeErrorMessage;
  if (isEndRangeEmpty) {
    endRangeErrorMessage = "Filter value must not be empty.";
  } else if (isEndRangeNumericAndInvalid) {
    endRangeErrorMessage =
      "Provide a numeric value for this SQL operator type.";
  } else if (isRangeOutOfBounds) {
    endRangeErrorMessage = "Start Range must not exceed End Range";
  } else {
    endRangeErrorMessage = "";
  }

  const isStartRangeInvalid =
    isStartRangeEmpty || isStartRangeNumericAndInvalid || isRangeOutOfBounds;
  const isEndRangeInvalid =
    isEndRangeEmpty || isEndRangeNumericAndInvalid || isRangeOutOfBounds;

  useEffect(() => {
    if (isStartRangeInvalid || isEndRangeInvalid) {
      setIsConfirmButtonDisabled(true);
    } else {
      setIsConfirmButtonDisabled(false);
    }
  }, [isStartRangeInvalid, isEndRangeInvalid]); // eslint-disable-line

  return (
    <>
      <Col className="ps-0 pe-0">
        <CDataTextField
          type="text"
          aria-label="Filter Start Range"
          value={queryFilter.data.start ?? ""}
          onChange={(e) => {
            setQueryFilter({
              ...queryFilter,
              data: {
                start: e.target.value,
                end: queryFilter.data.end,
              },
            });
          }}
          onBlur={() => {
            setIsStartRangeTouched(true);
          }}
          isInvalid={isStartRangeTouched && Boolean(startRangeErrorMessage)}
          className={classnames("filter-value-combo-input", {
            "pe-2": queryFilter.filterType === FilterType.Between,
          })}
          disabled={isDisabled}
        />
        {isStartRangeTouched && Boolean(startRangeErrorMessage) && (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {startRangeErrorMessage}
          </CDataTypography>
        )}
      </Col>
      <Col className="flex-grow-0 align-content-center px-0">and</Col>
      <Col className="ps-2 range-column">
        <CDataTextField
          type="text"
          aria-label="Filter End Range"
          value={queryFilter.data.end ?? ""}
          onChange={(e) => {
            props.setQueryFilter({
              ...props.queryFilter,
              data: {
                start: props.queryFilter.data.start,
                end: e.target.value,
              },
            });
          }}
          onBlur={() => {
            setIsEndRangeTouched(true);
          }}
          isInvalid={isEndRangeTouched && Boolean(endRangeErrorMessage)}
          disabled={isDisabled}
        />
        {isEndRangeTouched && Boolean(endRangeErrorMessage) && (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {endRangeErrorMessage}
          </CDataTypography>
        )}
      </Col>
    </>
  );
}
