import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrSuccess } from "../../../../services/toastrService";
import { Spinner } from "reactstrap";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { QueryTabType } from "../Tabs/queryTabType";
import { useMutation } from "@tanstack/react-query";
import { createSavedQuery } from "../../api/createSavedQuery";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import {
  IconOverrideOptions,
  NotificationBar,
} from "../../../../components/notification/NotificationBar";
import { dataExplorerConstants } from "../../DataExplorer.constants";

export type CreateSavedQueryModalProps = {
  tab: IQueryTab;
  displayed: boolean;
  close: () => void;
  fetchSavedQueries: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
};

export function CreateSavedQueryModal(props: CreateSavedQueryModalProps) {
  const tabs = useQueryTabs();

  const { tab, displayed, close, fetchSavedQueries, setSidebarView } = props;

  const {
    mutateAsync: createSavedQueryAsync,
    isPending: isCreateSavedQueryPending,
  } = useMutation({
    mutationKey: ["/account/derivedViews/create"],
    mutationFn: createSavedQuery,
    meta: {
      errorMessage: "Failed to get column metadata due to the following error:",
    },
    onSuccess: (data, variables) => {
      ToastrSuccess(
        "Saved Query successfully created",
        `${variables.name} was successfully created.`,
      );
      close();
      tabs.ChangeTabType(
        tab.id,
        variables.name ?? "",
        QueryTabType.SavedQuery,
        data.data.id ?? "",
        data.data.query ?? "",
      );
      fetchSavedQueries();
      setSidebarView(LeftPanelType.SavedQueries);
    },
  });

  const onSubmit = async (values: any) => {
    await createSavedQueryAsync(values);
  };

  const initialFormValues = {
    name: "",
    query: tab.queryString,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Saved Query name must be between 2 and 128 characters")
      .max(128, "Saved Query name must be between 2 and 128 characters")
      .required("This is a required field"),
    query: Yup.string()
      .max(10000, "Query cannot exceed 10000 characters")
      .required("This is a required field"),
  });

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Save as Saved Query"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            type="submit"
            form="newSavedQueryForm"
            data-testid="button-confirm"
          >
            Save
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={close}
            data-testid="button-cancel"
          >
            Cancel
          </CDataButton>
        }
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="newSavedQueryForm" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <CDataTypography
                  className="mb-2"
                  color="typography-color-text-muted"
                  variant="typography-variant-body-medium"
                >
                  {dataExplorerConstants.SAVED_QUERY_INFO}
                </CDataTypography>
                <Form.Label className="required h5">Query Name</Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  placeholder="Enter a name for your saved query."
                  value={values.name}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <NotificationBar
                iconOverride={IconOverrideOptions.Bulb}
                barColor="notification-bar-light-blue"
                message={
                  <CDataTypography
                    variant="typography-variant-body-medium"
                    color="typography-color-dark-blue"
                  >
                    For integration with external tools, we recommend saving
                    this as a Derived View.
                  </CDataTypography>
                }
              />
            </Form>
          )}
        </Formik>
      </CDataModalV2>
      <div hidden={!isCreateSavedQueryPending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
