import { useState } from "react";
import {
  ButtonBase,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { Badge, Input } from "reactstrap";
import Loader from "../Loader";
import classNames from "classnames";
import { CDataTextField } from "../form/CDataTextField";
import { produce } from "immer";
import "./CheckboxMultiSelect.scss";

export type CheckboxMultiSelectProps = Omit<
  SelectProps<string[]>,
  "multiple" | "onChange" | "value" | "variant"
> & {
  /** If set, this shows a loading spinner in the menu if the user clicks the dropdown */
  isLoading?: boolean;
  selectedValues: string[];
  allItems: string[];
  onChange: (values: string[]) => void;
};
/**
 * A wrapper around the material-ui Select component that allows checking multiple options in the dropdown.
 * The items in the dropdown are displayed as chips with an X icon that allows them to be removed.
 */
export function CheckboxMultiSelect(props: CheckboxMultiSelectProps) {
  const {
    onChange,
    allItems,
    selectedValues,
    className,
    isLoading = false,
    ...otherProps
  } = props;

  // This is just used to control the icon, this state IS NOT used to control if the dropdown is open or not.
  const [isOpen, setIsOpen] = useState(props.open ?? false);

  function onCheckItem(event: SelectChangeEvent<string[]>) {
    const value = event.target.value;

    // On autofill we get a stringified value.
    // This is from the MUI docs:
    // https://mui.com/material-ui/react-select/#checkmarks
    const checkedValues = typeof value === "string" ? value.split(",") : value;
    onChange(checkedValues);
  }

  function onRemoveItemAtIndex(index: number) {
    onChange(
      produce(props.selectedValues, (draft) => {
        draft.splice(index, 1);
      }),
    );
  }

  const children = isLoading ? (
    <MenuItem key="loader">
      <Loader />
    </MenuItem>
  ) : (
    allItems.map((item) => (
      <MenuItem key={item} value={item} className="checkboxMultiSelect-item">
        <Input
          type="checkbox"
          checked={selectedValues.indexOf(item) > -1}
          onChange={() => {
            // no-op, stops a console error.
          }}
        />
        <ListItemText primary={item} />
      </MenuItem>
    ))
  );

  return (
    <Select<string[]>
      displayEmpty
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span>Select</span>;
        }
        return selected.map((s, index) => (
          <Badge key={s} color="" className="multiSelectChip">
            <span>{s}</span>

            <ButtonBase
              aria-label={`Remove column ${s}`}
              // This stops the MUI dropdown from picking up the click on the X button and opening the dropdown
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onClick={(event) => {
                event.stopPropagation();
                onRemoveItemAtIndex(index);
              }}
              className="multiSelectChip-closeButton"
            >
              <i className="fa fa-times fa-solid multiSelectChip-closeIcon align-content-center" />
            </ButtonBase>
          </Badge>
        ));
      }}
      input={<CDataTextField />}
      IconComponent={() => (
        <i
          className={classNames("dropdown-icon fa-regular", {
            "fa-chevron-up": isOpen,
            "fa-chevron-down": !isOpen,
          })}
        />
      )}
      {...otherProps}
      onOpen={(event) => {
        setIsOpen(true);
        if (props.onOpen) props.onOpen(event);
      }}
      onClose={(event) => {
        setIsOpen(false);
        if (props.onClose) props.onClose(event);
      }}
      className={classNames("checkboxMultiSelect", className)}
      value={selectedValues}
      multiple
      onChange={onCheckItem}
      MenuProps={{
        className: "checkboxMultiSelect-menu",
      }}
    >
      {children}
    </Select>
  );
}
