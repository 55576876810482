import { MouseEventHandler, useState } from "react";
import {
  ButtonDropdown,
  ButtonDropdownProps,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
  TooltipProps,
} from "reactstrap";

import { getSettingsPageSubscriptionTab } from "../../../../utility/SubscriptionProvider";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { FeatureId, UserRole } from "../../../../models";
import { jobsConstants } from "../../../jobs/Jobs.constants";
import { isFeatureAllowed } from "src/utility/SubscriptionAddonsFactory";
import { useAppSelector } from "src/redux/hooks";
import { CreateDerivedViewModal } from "../modals/CreateDerivedViewModal";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";
import { CreateSavedQueryModal } from "../modals/CreateSavedQueryModal";
import { IQueryTab } from "../Tabs/useQueryTabs";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";
import { InfoIcon } from "../../../../components/InfoIcon";
import { dataExplorerConstants } from "../../DataExplorer.constants";

export interface IQueryEditorSaveButton {
  tab: IQueryTab;
  areDerivedViewsAllowed: boolean;
  connectionsOverLimit: boolean;
  createDropdownOpen: boolean;
  isSaveChangesVisible: boolean;
  openSaveChangesModal: MouseEventHandler<HTMLElement>;
  areSaveAsButtonsDisabled: boolean;
  toggle: TooltipProps["toggle"];
  toggleCreateDropdown: ButtonDropdownProps["toggle"];
  tooltipOpen: boolean;
  usersOverLimit: boolean;
  queryText: string;
  setSidebarView: (sidebarView: LeftPanelType) => void;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
}

export const QueryEditorSaveButton = (props: IQueryEditorSaveButton) => {
  const navigate = useNavigate();
  const flags = useFeatureFlags().getFlags(["scheduled_queries"]);
  const subscription = useAppSelector((state) => state.subscription);
  const user = useAppSelector((state) => state.user);
  const isQueryUser = user.role === UserRole.Query;

  const [createDerivedViewModalOpen, setCreateDerivedViewModalOpen] =
    useState<boolean>(false);
  const [createSavedQueryModalOpen, setCreateSavedQueryModalOpen] =
    useState<boolean>(false);

  const isScheduledQueriesEnabled =
    !isQueryUser &&
    isFeatureAllowed(
      subscription?.limits?.availableFeatureIds ?? [],
      FeatureId.ScheduledQueries,
    );

  const addScheduledQuery = () => {
    navigate(`/jobs/editScheduledQuery/${jobsConstants.DEFAULT_JOB_ID}`, {
      state: {
        queryText: props.queryText,
      },
    });
  };

  return (
    <>
      <ButtonDropdown
        isOpen={props.createDropdownOpen}
        toggle={props.toggleCreateDropdown}
        className="save-dropdown"
      >
        <DropdownToggle
          caret
          outline
          color="primary"
          className="save-dropdown-toggle"
          data-testid="save-dropdown-toggle"
          disabled={props.connectionsOverLimit || props.usersOverLimit}
        >
          <span>
            <i className="fa fa-edit align-middle add-connection-icon no-pointer-event" />
            Save
          </span>
        </DropdownToggle>
        <DropdownMenu className="save-dropdown-menu">
          <div id="saveDerivedView" className="save-dropdown-menu-item">
            <DropdownItem
              onClick={(event) => {
                event.preventDefault();
                setCreateDerivedViewModalOpen(true);
              }}
              disabled={props.areSaveAsButtonsDisabled}
              hidden={
                isQueryUser ||
                subscription.plan?.id === BillingPlanIds.Standard2025
              }
              className={
                !props.areDerivedViewsAllowed ? "disabled-feature" : ""
              }
              data-testid="create-derived-view"
            >
              <i className="fa fa-border-none fa-solid align-middle save-icon no-pointer-event" />
              Save as Derived View
              <InfoIcon
                tooltipMessage={dataExplorerConstants.DERIVED_VIEW_INFO}
              />
            </DropdownItem>
          </div>
          <div className="save-dropdown-menu-item">
            <DropdownItem
              onClick={(event) => {
                event.preventDefault();
                setCreateSavedQueryModalOpen(true);
              }}
              disabled={props.areSaveAsButtonsDisabled}
              color="secondary"
              data-testid="create-saved-query"
              className={
                props.areSaveAsButtonsDisabled ? "no-pointer-event" : ""
              }
            >
              <i className="fa-solid fa-clipboard align-middle save-icon no-pointer-event" />
              Save as Saved Query
              <InfoIcon
                tooltipMessage={dataExplorerConstants.SAVED_QUERY_INFO}
              />
            </DropdownItem>
          </div>
          {flags.scheduled_queries.enabled && isScheduledQueriesEnabled && (
            <DropdownItem
              onClick={(event) => {
                event.preventDefault();
                addScheduledQuery();
              }}
              disabled={props.areSaveAsButtonsDisabled}
              color="secondary"
              data-testid="create-scheduled-query"
              className={
                props.areSaveAsButtonsDisabled
                  ? "me-2 no-pointer-event"
                  : "me-2"
              }
            >
              <i className="fa fa-solid fa-clock save-icon align-middle no-pointer-event" />
              Save as Scheduled Query
            </DropdownItem>
          )}
          {props.isSaveChangesVisible && (
            <>
              <Box sx={{ borderTop: 1 }} className="divider" />
              <DropdownItem
                onClick={props.openSaveChangesModal}
                color="secondary"
                data-testid="button-save-changes"
                disabled={props.areSaveAsButtonsDisabled}
              >
                <i className="fa fa-save align-middle save-icon no-pointer-event" />
                Save Changes
              </DropdownItem>
            </>
          )}
          {!props.areDerivedViewsAllowed ? (
            <Tooltip
              placement="left"
              target="saveDerivedView"
              autohide={false}
              toggle={props.toggle}
              isOpen={props.tooltipOpen}
            >
              <a
                className="upgrade-link"
                onClick={() =>
                  navigate(
                    "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                  )
                }
              >
                Upgrade
              </a>
              &nbsp; your subscription with our Virtualization Package add-on to
              use this feature.
            </Tooltip>
          ) : null}
        </DropdownMenu>
      </ButtonDropdown>
      <CreateDerivedViewModal
        tab={props.tab}
        displayed={createDerivedViewModalOpen}
        close={() => setCreateDerivedViewModalOpen(false)}
        fetchDerivedViews={props.fetchDerivedViews}
        setSidebarView={props.setSidebarView}
      />
      <CreateSavedQueryModal
        tab={props.tab}
        displayed={createSavedQueryModalOpen}
        close={() => setCreateSavedQueryModalOpen(false)}
        fetchSavedQueries={props.fetchSavedQueries}
        setSidebarView={props.setSidebarView}
      />
    </>
  );
};
