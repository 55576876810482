import { useState } from "react";
import { Col, DropdownItemProps, Row } from "reactstrap";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { QueryEditorSaveButton } from "../QueryEditor/QueryEditorSaveButton";
import { IQueryTab } from "../Tabs/useQueryTabs";
import { useAppSelector } from "../../../../redux/hooks";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";

interface IManageDerivedViewButtons {
  tab: IQueryTab;
  queryInput: string;
  handleSaveClick: DropdownItemProps["onClick"];
  handleRefreshClickCallback: React.MouseEventHandler<HTMLButtonElement>;
  openSaveChangesModal: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
}

export const ManageDerivedViewButtons = (props: IManageDerivedViewButtons) => {
  const { tab, queryInput, handleRefreshClickCallback, openSaveChangesModal } =
    props;

  const usage = useAppSelector((state) => state.usage);

  const [createDropdownOpen, setCreateDropdownOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  //Dropdown needs more time to close in order for tooltip routing not to break.
  const toggleCreateDropdown = () => {
    setTimeout(() => {
      setCreateDropdownOpen(!createDropdownOpen);
    }, 100);
  };

  return (
    <Row className="data-explorer-top-row align-items-center mx-0">
      <Col className="ps-0">
        <CDataButton
          data-testid="button-clear"
          buttonType={ButtonType.Tertiary}
          onClick={handleRefreshClickCallback}
          className="ms-2"
          disabled={queryInput === ""}
        >
          <i className="fa-regular fa-xmark align-middle me-2"></i>
          Clear
        </CDataButton>
      </Col>
      <Col>
        <div className="float-end limit-container">
          <QueryEditorSaveButton
            tab={tab}
            areDerivedViewsAllowed={true}
            connectionsOverLimit={usage.connectionsOverLimit!}
            createDropdownOpen={createDropdownOpen}
            openSaveChangesModal={openSaveChangesModal}
            isSaveChangesVisible={tab.unsavedChanges!}
            areSaveAsButtonsDisabled={false}
            toggle={() => setTooltipOpen(!tooltipOpen)}
            toggleCreateDropdown={toggleCreateDropdown}
            tooltipOpen={tooltipOpen}
            usersOverLimit={usage.usersOverLimit!}
            queryText={queryInput}
            setSidebarView={props.setSidebarView}
            fetchDerivedViews={props.fetchDerivedViews}
            fetchSavedQueries={props.fetchSavedQueries}
          />
        </div>
      </Col>
    </Row>
  );
};
