import { FeatureId, UserRole } from "../../models";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Tooltip } from "reactstrap";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import {
  DataExplorerPanelMetadata,
  IDataExplorerPanelMetadata,
} from "./LeftPanel/DataExplorerLeftPanelMetadata";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { LeftPanelType } from "./LeftPanel/leftPanelType";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";

interface ITopLevelNavigationProps {
  callbackOnClick: (value: LeftPanelType) => void;
  isScrollOpen: boolean;
}

interface ITopLevelNavTooltipItem {
  key: number;
  item: IDataExplorerPanelMetadata;
  id: number;
  callbackOnClickProp: (value: LeftPanelType) => void;
  areDerivedViewsAllowed: boolean;
  areScheduledQueriesAllowed: boolean;
}

const TopLevelNavTooltipItem = (props: ITopLevelNavTooltipItem) => {
  const navigate = useNavigate();
  const usage = useAppSelector((state) => state.usage);
  const user = useAppSelector((store) => store.user);

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const featureNotAllowed =
    ((!props.areScheduledQueriesAllowed &&
      props.item.name === "Scheduled Queries") ||
      (!props.areDerivedViewsAllowed && props.item.name === "Derived Views")) &&
    !(usage.connectionsOverLimit || usage.usersOverLimit);

  function toggle() {
    setTooltipOpen(!tooltipOpen);
  }

  const semiDisabledClass = classNames({
    "table-name-text data-explorer-container data-explorer-table-element": true,
    "semi-disabled": featureNotAllowed,
  });

  return (
    <div
      data-testid={`test-id-${props.id}`}
      id={`row-${props.id}`}
      className={semiDisabledClass}
      title={props.item.name}
      onClick={() => props.callbackOnClickProp(props.item.view)}
    >
      <i className={`fa ${props.item.icon} me-2 align-middle category-icon`} />
      <span className="align-middle truncate">{props.item.name}</span>
      <div className="ellipsis-right">
        <i className="fa fa-chevron-right align-middle" />
      </div>
      {featureNotAllowed ? (
        <Tooltip
          placement="left"
          isOpen={tooltipOpen}
          target={`row-${props.id}`}
          toggle={toggle}
          onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
          autohide={false}
        >
          {!(user.role === UserRole.Query) ? (
            <a
              className="upgrade-link"
              onClick={() =>
                navigate(
                  "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                )
              }
            >
              Upgrade
            </a>
          ) : (
            "Upgrade"
          )}
          &nbsp;your subscription with our Virtualization Package add-on to use
          this feature.
        </Tooltip>
      ) : null}
    </div>
  );
};

const TopLevelNavigation = (props: ITopLevelNavigationProps) => {
  const [areDerivedViewsAllowed, setAreDerivedViewsAllowed] =
    useState<boolean>(false);
  const [areScheduledQueriesAllowed, setAreScheduledQueriesAllowed] =
    useState<boolean>(false);
  const subscription = useAppSelector((state) => state.subscription);
  const isStandard2025Plan =
    subscription.plan?.id === BillingPlanIds.Standard2025;

  const queryCategoryColums = [
    {
      dataField: "name",
      text: "",
    },
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
  ];

  useEffect(() => {
    checkForAllowedFeatures();
  }, []); // eslint-disable-line

  function checkForAllowedFeatures() {
    const currentSubscription = subscription;
    setAreDerivedViewsAllowed(
      isFeatureAllowed(
        currentSubscription?.limits?.availableFeatureIds ?? [],
        FeatureId.DerivedViews,
      ),
    );
    setAreScheduledQueriesAllowed(
      isFeatureAllowed(
        currentSubscription?.limits?.availableFeatureIds ?? [],
        FeatureId.ScheduledQueries,
      ),
    );
  }

  const filteredCategories = DataExplorerPanelMetadata.filter((el) => {
    if (window.location.hostname === "cloud.cdata.com") {
      return (
        el.isQueryCategory === true &&
        el.prodVisible === true &&
        !(isStandard2025Plan && el.standard2025Visible === false)
      );
    } else {
      return (
        el.isQueryCategory === true &&
        !(isStandard2025Plan && el.standard2025Visible === false)
      );
    }
  });

  const formattedCategories = filteredCategories.map((category, index) => {
    return {
      name: (
        <TopLevelNavTooltipItem
          key={index}
          item={category}
          id={index}
          callbackOnClickProp={props.callbackOnClick}
          areDerivedViewsAllowed={areDerivedViewsAllowed}
          areScheduledQueriesAllowed={areScheduledQueriesAllowed}
        />
      ),
      id: index,
    };
  });

  return (
    <BootstrapTable
      bootstrap4
      classes={
        "data-explorer-tab-list mb-0" + (props.isScrollOpen ? " hasScroll" : "")
      }
      bordered={false}
      wrapperClasses="table-borderless px-0"
      keyField="id"
      data={formattedCategories}
      columns={queryCategoryColums}
      hover={true}
    />
  );
};

export default TopLevelNavigation;
