import { cdataFetchReturnHeaders } from "src/api/cdataFetch";
import { ISavedQuery } from "src/models/SavedQueries/ISavedQuery";

export async function createSavedQuery(body: ISavedQuery) {
  return cdataFetchReturnHeaders<ISavedQuery>({
    method: "POST",
    url: "/users/self/savedQueries",
    body: body,
  });
}
