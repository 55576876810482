import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrSuccess } from "../../../../services/toastrService";
import { Spinner } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { ISavedQuery } from "../../../../models/SavedQueries/ISavedQuery";
import { useMutation } from "@tanstack/react-query";
import { renameSavedQuery } from "../../api/renameSavedQuery";

export type RenameSavedQueryModalFormValues = {
  Name?: string;
  HiddenOldName?: string;
  id?: string;
};

export type RenameSavedQueryModalProps = {
  displayed: boolean;
  close: () => void;
  targetSavedQuery?: ISavedQuery;
  fetchSavedQueries: () => void;
};

export function RenameSavedQueryModal(props: RenameSavedQueryModalProps) {
  const {
    displayed,
    close,
    targetSavedQuery: savedQuery,
    fetchSavedQueries,
  } = props;

  const { mutateAsync: renameSavedQueryAsync, isPending: isRenamePending } =
    useMutation({
      mutationKey: ["/renameSavedQuery"],
      mutationFn: renameSavedQuery,
      meta: {
        errorMessage:
          "Failed to update saved query due to the following error:",
      },
      onSettled: () => {
        close();
      },
      onSuccess: () => {
        fetchSavedQueries();
        ToastrSuccess(
          "Saved Query successfully renamed",
          `${savedQuery?.name} was successfully renamed.`,
        );
      },
    });

  if (!savedQuery) {
    return null;
  }

  const initialFormValues: RenameSavedQueryModalFormValues = {
    Name: savedQuery.name,
    HiddenOldName: savedQuery.name,
    id: savedQuery.id,
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, "Saved Query name must be between 2 and 128 characters")
      .max(128, "Saved Query name must be between 2 and 128 characters")
      .required("This is a required field"),
  });

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Rename Query"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            type="submit"
            form="renameSavedQueryForm"
            data-testid="button-confirm"
          >
            Confirm
          </CDataButton>
        }
        secondaryButton={
          <CDataButton buttonType={ButtonType.Secondary} onClick={close}>
            Cancel
          </CDataButton>
        }
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values) => renameSavedQueryAsync(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="renameSavedQueryForm" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="required h5">Query Name</Form.Label>
                <Form.Control
                  id="Name"
                  type="text"
                  placeholder="Enter a new name for your saved query."
                  value={values.Name}
                  isInvalid={Boolean(touched.Name && errors.Name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.Name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.Name?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      </CDataModalV2>
      <div hidden={!isRenamePending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
