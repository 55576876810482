import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  IPopoverOption,
  PopoverList,
} from "../../../../components/popover/PopoverList";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { CreateAssetModal } from "../../../datasets/components/CreateAssetModal";
import { FeatureId, UserRole } from "../../../../models";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import useQueryTabs from "../../RightPanel/Tabs/useQueryTabs";
import { throttle } from "lodash";
import { useNavigate } from "react-router-dom";
import { ITableToCacheJobMap } from "../useTableToCacheJobMap";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import { useAppSelector } from "../../../../redux/hooks";
import { QueryTabType } from "../../RightPanel/Tabs/queryTabType";
import { jobsConstants } from "../../../jobs/Jobs.constants";
import CustomReportTooltip from "../../../../components/CustomReportTooltip";
import { ConnectionType } from "../../../../models/Connections/ConnectionType";
import { isFeatureAllowed } from "src/utility/SubscriptionAddonsFactory";

export interface ISchemaTablesListProps {
  connectionId: string | undefined;
  connectionName: string;
  selectedDriver: string;
  tables: SchemaTables[];
  filteredOutSchemas: number[];
  searchQuery: string | null;
  clickDisabled: boolean;
  disableTableClick: (disabled: boolean) => void;
  isCachingSupportedForDriver: boolean;
  tableToCacheJobMap: ITableToCacheJobMap;
  customReportList: Set<string | undefined> | undefined;
  connectionType: ConnectionType | undefined;
}
// Define the type of data that will be used in the table
type SchemaTables = {
  name: string;
  type: string;
  schema: string;
  isCustomReport?: boolean;
};

interface SchemaItemProps {
  cell: any;
  itemData: {
    name: string;
    type: string;
    schema: string;
  };
  clickDisabled: boolean;
  connectionName: string;
  selectedDriver: string;
  index: number;
  selectedSchema: any;
  disableTableClick: (disabled: boolean) => void;
  connectionId: string | undefined;
  isQueryUser: boolean;
  isCachingSupportedForDriver: boolean;
  /** True if the table is currently cached. */
  isCaching: boolean;
  connectionType: ConnectionType | undefined;
}

const SchemaItem = memo((props: SchemaItemProps) => {
  const {
    clickDisabled,
    itemData,
    cell,
    index,
    connectionId,
    disableTableClick,
    isCaching,
  } = props;
  const [isMoveWorkspaceOpen, setIsMoveWorkspaceOpen] =
    useState<boolean>(false);

  const tabs = useQueryTabs();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.user.role);
  const cacheConnection = useAppSelector((state) => state.cacheConnection);
  const cacheConnectionId = cacheConnection?.id;
  const subscription = useAppSelector((state) => state.subscription);

  const isCachingEnabledForAccount = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.Caching,
  );

  const { name, schema, type, isCustomReport } = cell.row.original;

  const handleTableClick = () => {
    disableTableClick(true);
    tabs.AddGenericTab(
      itemData.type as QueryTabType,
      props.connectionName,
      props.selectedSchema,
      itemData.name,
      "open",
    );
    disableTableClick(false);
  };

  function addCacheJob() {
    navigate(`/jobs/editCacheJob/${jobsConstants.DEFAULT_JOB_ID}`, {
      state: {
        connectionId: connectionId,
        connectionName: props.connectionName,
        schema: props.selectedSchema,
        tableName: itemData.name,
        driver: props.selectedDriver,
      },
    });
  }

  const popoverOptions: IPopoverOption[] = [
    {
      label: "Open",
      action: () =>
        tabs.AddGenericTab(type, props.connectionName, schema, name, "open"),
    },
    {
      label: "Query",
      action: () =>
        tabs.AddGenericTab(type, props.connectionName, schema, name, "query"),
    },
  ];

  if (!props.isQueryUser) {
    popoverOptions.push({
      label: "Add to Workspace",
      action: () => setIsMoveWorkspaceOpen(true),
    });
  }

  if (
    isCachingEnabledForAccount &&
    props.isCachingSupportedForDriver &&
    props.selectedDriver !== "REST" &&
    !props.isQueryUser
  ) {
    popoverOptions.push({
      label: "Add Cache Job",
      action: () => addCacheJob(),
      disabled: !cacheConnectionId,
    });
  }

  popoverOptions.push({
    label: "Query Builder",
    action: () =>
      tabs.AddQueryBuilderTab(
        props.connectionName,
        props.connectionId,
        schema,
        name,
        props.selectedDriver,
      ),
  });

  return (
    <div className="justify-content-between schemaTablesList-schemaItem">
      <div
        className="table-name-text-container data-explorer-container"
        title={name}
      >
        <div
          className={classNames(
            "table-name-text tableNameContainer",
            clickDisabled ? "disable-table-click" : "cursor-pointer",
          )}
          onClick={() => handleTableClick()}
        >
          <span className="tableNameAndIcon">
            {isCustomReport ? (
              <CustomReportTooltip
                id={props.connectionId!}
                isCRAvailable={true}
                selectedDriver={props.selectedDriver}
                connectionType={props.connectionType!}
                currentUserRole={userRole}
                color="gray"
                openEditConnectionPage={() => {
                  navigate("/connections/edit", {
                    state: {
                      driverType: props.selectedDriver,
                      connectionId: props.connectionId,
                      hasCustomReports: true,
                      currentTab: "4",
                    },
                  });
                }}
              />
            ) : (
              <i
                className={classNames("align-middle table-icon fa-solid", {
                  "fa-file": isCustomReport,
                  "fa-table": !isCustomReport && type === "VIEW",
                  "fa-border-none": !isCustomReport && type === "TABLE",
                })}
                data-testid={
                  type === "VIEW"
                    ? "schema-tables-list-view"
                    : "schema-tables-list-table"
                }
              />
            )}
            <span className="align-middle truncate">{name}</span>
          </span>

          {isCaching && (
            <Tooltip title="Caching">
              <i
                color=""
                className="fa cachingIcon fa-solid fa-copy"
                data-testid="cachingIcon"
              />
            </Tooltip>
          )}
        </div>

        <div
          id={"Popover-" + index}
          data-testid={"table-popover-" + index}
          className="ellipsis-right cursor-pointer tableMenu"
        >
          <i className="align-middle fa-solid fa-ellipsis-vertical fa-lg" />
          <PopoverList
            target={"Popover-" + index}
            key={index}
            popoverOptions={popoverOptions}
          />
        </div>
        {isMoveWorkspaceOpen && (
          <CreateAssetModal
            isModalOpen={isMoveWorkspaceOpen}
            setIsMoveWorkspaceOpen={setIsMoveWorkspaceOpen}
            isDerivedView={false}
            connectionId={connectionId}
            schemaName={schema as string}
            tableName={name as string}
          />
        )}
      </div>
    </div>
  );
});
SchemaItem.displayName = "SchemaItem";

function SchemaTablesList(props: ISchemaTablesListProps) {
  const itemsPerScroll = 50;
  const userInfo = useUserInfo();
  const [filteredTableRows, setFilteredTableRows] = useState<SchemaTables[]>(
    [],
  );
  // Create a column helper for the SchemaTables data type
  const columnHelper = createColumnHelper<SchemaTables>();
  // Defining the columns for the table using the column helper object
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info: any) => info.getValue(),
        footer: (info: any) => info.column.id,
      }),
    ],
    [columnHelper],
  );

  // Essentially, this useEffect hook is updating the state of the filteredTableRows array based on changes to the filteredOutSchemas prop.
  useEffect(() => {
    const newFilteredTableRows = props.tables
      .filter(
        (item, index) =>
          !props.filteredOutSchemas.includes(index) &&
          (props.searchQuery === null ||
            item.name?.toLowerCase().includes(props.searchQuery.toLowerCase())),
      )
      .map((nft) => ({
        ...nft,
        isCustomReport: props.customReportList?.has(nft.name),
      }));

    setFilteredTableRows(newFilteredTableRows);
  }, [props.filteredOutSchemas, props.searchQuery]); // eslint-disable-line

  const [data, setData] = useState(
    filteredTableRows.slice(
      0,
      filteredTableRows.length > itemsPerScroll
        ? itemsPerScroll
        : filteredTableRows.length,
    ),
  );

  // This line uses the useReactTable hook from @tanstack/react - table to create a table instance with the provided data, columns, and core row model.
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  // This useEffect hook updates the data and sets the "hasMore" flag based on the filteredTableRows state.
  useEffect(() => {
    setData(
      filteredTableRows.slice(
        0,
        filteredTableRows.length > itemsPerScroll
          ? itemsPerScroll
          : filteredTableRows.length,
      ),
    );
  }, [filteredTableRows]);

  // This function is responsible for fetching more data for the infinite scroll feature.
  // eslint-disable-next-line
  const fetchMoreData = useCallback(
    throttle(() => {
      if (data.length >= filteredTableRows.length) {
        return;
      }
      setData((currentData) => {
        const newData = filteredTableRows.slice(
          currentData.length,
          currentData.length + itemsPerScroll,
        );
        return [...currentData, ...newData];
      });
    }, 100),
    [filteredTableRows],
  );

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={filteredTableRows.length > itemsPerScroll}
      loader={null}
      scrollableTarget="main-sidebar-content"
    >
      <table className="table table-borderless table-hover schemaTableList">
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            const currentSchema = row.original.schema;

            const cacheJob = props.tableToCacheJobMap.getCacheJobForTable({
              connectionName: props.connectionName,
              schemaName: currentSchema,
              tableName: data[index].name,
            });
            const isCaching = cacheJob != null && cacheJob.enabled === true;
            return (
              <tr
                key={index}
                // @ts-ignore prexisting issue, needs refactor
                // eslint-disable-next-line
                tableName={data[index].name}
                // eslint-disable-next-line
                schemaName={currentSchema}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="data-explorer-column-name">
                    <SchemaItem
                      key={index}
                      cell={cell}
                      connectionName={props.connectionName}
                      selectedDriver={props.selectedDriver}
                      itemData={data[index]}
                      connectionId={props.connectionId}
                      clickDisabled={props.clickDisabled}
                      index={index}
                      selectedSchema={currentSchema}
                      disableTableClick={props.disableTableClick}
                      isQueryUser={userInfo.IsInRole(UserRole.Query)}
                      isCachingSupportedForDriver={
                        props.isCachingSupportedForDriver
                      }
                      isCaching={isCaching}
                      connectionType={props.connectionType}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}

export default SchemaTablesList;
