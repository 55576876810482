import { cdataFetchReturnHeaders } from "src/api/cdataFetch";
import { IDerivedView, IPartialDerivedView } from "src/models";

export async function updateDerivedView(
  derivedViewId: string,
  body: IPartialDerivedView,
) {
  return cdataFetchReturnHeaders<IDerivedView>({
    method: "PUT",
    url: `/account/derivedViews/${encodeURIComponent(derivedViewId)}`,
    body: body,
  });
}
