import { IQueryResult } from "src/models";
import { IServiceError } from "src/models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export async function getSchemas(params: {
  connection: string;
  headers?: Record<string, string>;
}) {
  return cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/schemas?catalogName=${encodeURIComponent(params.connection)}`,
    headers: params.headers,
  });
}
