import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import { useContext, useState } from "react";
import { Spinner } from "reactstrap";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { useDuplicateColumnFinder } from "./useDuplicateColumnFinder";
import { useMutation } from "@tanstack/react-query";
import { updateDerivedView } from "../../api/updateDerivedView";
import { IPartialDerivedView } from "src/models";

export type UpdateDerivedViewModalProps = {
  tab: IQueryTab;
  displayed: boolean;
  close: () => void;
  fetchDerivedViews: () => void;
  derivedViewTitle?: string; // only present if on the edit derived view page
};

export function UpdateDerivedViewModal(props: UpdateDerivedViewModalProps) {
  const tabs = useQueryTabs();
  const tabContext = useContext(QueryTabsContext);
  const { findDuplicateColumns } = useDuplicateColumnFinder();

  const { tab, displayed, close, fetchDerivedViews, derivedViewTitle } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { mutateAsync: createDerivedViewAsync } = useMutation({
    mutationKey: ["/account/derivedViews/update"],
    mutationFn: ({
      id,
      body,
      isEdit, // eslint-disable-line @typescript-eslint/no-unused-vars -- this is used in the onSuccess method
    }: {
      id: string;
      body: IPartialDerivedView;
      isEdit: boolean;
    }) => updateDerivedView(id, body),
    meta: {
      errorMessage: "Failed to get column metadata due to the following error:",
    },
    onSuccess: (data, variables) => {
      if (!variables.isEdit) {
        ToastrSuccess(
          "Derived View successfully saved",
          `${props.tab.tabName} was successfully updated.`,
        );
        fetchDerivedViews();

        if (tab.parentTabId) {
          const originalTab = tabs.List.find(
            (tab) => tab.id === tab.parentTabId,
          );
          tabContext.setTabs((previousTabs) => {
            const newTabs = createDeepCopy(previousTabs);
            const rowToMutate = newTabs.find((t) => t.id === originalTab?.id);
            if (rowToMutate) {
              rowToMutate.queryString = tab.queryString;
            }

            return newTabs;
          });
        }

        tabs.SetUnsavedChanges(tabs.CurrentTabId, false);
      } else {
        ToastrSuccess(
          "Derived View successfully saved",
          `${derivedViewTitle} was successfully updated.`,
        );

        fetchDerivedViews();

        // Update the tab title on save
        tabContext.setTabs((previousTabs) => {
          const newTabs = createDeepCopy(previousTabs);
          const rowToMutate = newTabs.find(
            (tab) => tab.id === tabs.CurrentTabId,
          );
          if (rowToMutate) {
            rowToMutate.tabName = derivedViewTitle!;
            rowToMutate.tableName = derivedViewTitle;
            rowToMutate.unsavedChanges = true;
          }

          return newTabs;
        });
      }

      close();
    },
  });

  const onSubmit = async () => {
    setIsProcessing(true);

    const trimmedQueryInput = props.tab.queryString.trim();
    const values = {
      query: trimmedQueryInput,
    };

    //verify duplicate column names before actually saving the dervied view.
    const { duplicateColumns, queryId, invalidQuery } =
      await findDuplicateColumns(trimmedQueryInput);

    if (invalidQuery) {
      //if '/query' call throws error, then don't proceed to save the derived view.
      close();
      return;
    }

    if (duplicateColumns?.length > 0) {
      const duplicateColumnNames = duplicateColumns.join(", ");
      ToastrError(
        "Duplicate column names detected",
        `Unable to save derived view due to an unresolved SQL compilation error. Ambiguous column names: ${duplicateColumnNames}.`,
        `Query ID: ${queryId}`,
      );
      close();
      return;
    }

    await createDerivedViewAsync({
      id: props.tab.uniqueId ?? "",
      body: values,
      isEdit: false,
    });

    setIsProcessing(false);
  };

  const updateEditDerivedView = async () => {
    setIsProcessing(true);
    const trimmedQueryInput = props.tab.queryString.trim();
    const values = {
      name: derivedViewTitle, // I don't think we can use tab name here
      query: trimmedQueryInput,
    };

    //verify duplicate column names before actually saving the dervied view.
    const { duplicateColumns, queryId, invalidQuery } =
      await findDuplicateColumns(trimmedQueryInput);

    if (invalidQuery) {
      //if '/query' call throws error, then don't proceed to save the derived view.
      close();
      return;
    }

    if (duplicateColumns?.length > 0) {
      const duplicateColumnNames = duplicateColumns.join(", ");
      ToastrError(
        "Duplicate column names detected",
        `Unable to save derived view due to an unresolved SQL compilation error. Ambiguous column names: ${duplicateColumnNames}.`,
        `Query ID: ${queryId}`,
      );
      close();
      return;
    }

    await createDerivedViewAsync({
      id: props.tab.uniqueId ?? "",
      body: values,
      isEdit: true,
    });

    setIsProcessing(false);
  };

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Save Changes"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={derivedViewTitle ? onSubmit : updateEditDerivedView}
          >
            Save
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={close}
            data-testid="button-cancel"
          >
            Cancel
          </CDataButton>
        }
      >
        You are about to overwrite {props.tab.tabName} with new changes. Are you
        sure you want to proceed?
      </CDataModalV2>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
