import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  customFilter,
  CustomFilterProps,
} from "react-bootstrap-table2-filter";
import { Spinner } from "reactstrap";
import { IDerivedView } from "../../../../models";
import { useState } from "react";
import { DeleteDerivedViewModal } from "./DeleteDerivedViewModal";
import { DerivedViewItem } from "./DerivedViewItem";

interface IDerivedViewsTableProps {
  noData: () => JSX.Element;
  filterSearch: CustomFilterProps;
  derivedViews: IDerivedView[];
  derivedViewsLoading: boolean;
  filterDerivedViews: (
    filterVal: string,
    derivedViews: IDerivedView[],
  ) => IDerivedView[];
  callbackSearchFilter: (onSearchFilter: CustomFilterProps) => void;
  isQueryUser: boolean;
  fetchDerivedViews: () => void;
}

const DerivedViewsTable = (props: IDerivedViewsTableProps) => {
  const [targetDerivedView, setTargetDerivedView] =
    useState<IDerivedView | null>(null);
  const [deleteDerivedViewModalOpen, setDeleteDerivedViewModalOpen] =
    useState<boolean>(false);

  let onSearchFilter = props.filterSearch;

  const renderDerivedViewsTable = () => {
    const derivedViewColumns = [
      {
        dataField: "name",
        text: "",
        filter: customFilter({
          // @ts-ignore prexisting issue, needs refactor
          onFilter: (input, rows) => props.filterDerivedViews(input, rows),
        }),
        filterRenderer: (onFilter: any): any => {
          onSearchFilter = onFilter;
          props.callbackSearchFilter(onSearchFilter);
          return null;
        },
        formatter: (cell: any, row: any, rowIndex: number) => {
          return (
            <DerivedViewItem
              row={row}
              rowIndex={rowIndex}
              setTargetDerivedView={setTargetDerivedView}
              isQueryUser={props.isQueryUser}
              setDeleteDerivedViewModalOpen={setDeleteDerivedViewModalOpen}
            />
          );
        },
      },
      {
        dataField: "query",
        text: "",
        hidden: true,
      },
    ];

    if (props.derivedViewsLoading) {
      return (
        <div
          className="loading-table-list"
          data-testid="derived-view-list-spinner"
        >
          <Spinner color="primary" />
        </div>
      );
    } else {
      return (
        <BootstrapTable
          bootstrap4
          bordered={false}
          classes="data-explorer-tab-list"
          wrapperClasses="generic-panel-table table-borderless px-0"
          rowClasses="generic-panel-table-row"
          keyField="name"
          data={props.derivedViews}
          columns={derivedViewColumns}
          hover={true}
          filter={filterFactory()}
          noDataIndication={props.noData}
        />
      );
    }
  };

  return (
    <>
      {renderDerivedViewsTable()}
      <DeleteDerivedViewModal
        displayed={deleteDerivedViewModalOpen}
        close={() => setDeleteDerivedViewModalOpen(false)}
        fetchDerivedViews={props.fetchDerivedViews}
        targetDerivedView={targetDerivedView}
        setTargetDerivedView={setTargetDerivedView}
      />
    </>
  );
};

export default DerivedViewsTable;
