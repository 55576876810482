import { useEffect, useMemo, useState } from "react";
import { IQueryResult } from "../../../../../../models";
import {
  ToastrClean,
  ToastrError,
} from "../../../../../../services/toastrService";
import {
  sanitizeQuery,
  sanitizeQueryProps,
} from "../../../QueryEditor/sanitizeQuery";
import ResultsDetailsGridContainer from "../../../Results/ResultsDetailsGridContainer";
import { IQueryBuilderModel } from "../../models/IQueryBuilderModel";
import { generateQueryBuilderSql } from "../../sqlGenerator/generateQueryBuilderSql";
import { IQueryTab } from "../../../Tabs/useQueryTabs";
import { useMutation } from "@tanstack/react-query";
import { postQuery } from "src/pages/dataExplorer/api/postQuery";

type QueryBuilderPreviewTabProps = {
  tab: IQueryTab;
  queryData: IQueryBuilderModel;
  abortRunningQueryRequest: () => void;
};

export function QueryBuilderPreviewTab(props: QueryBuilderPreviewTabProps) {
  const { tab, queryData } = props;

  const [results, setResults] = useState<IQueryResult | null>(null);

  const { mutateAsync: executeQueryAsync, isPending: isExecutePending } =
    useMutation({
      mutationKey: ["/query"],
      mutationFn: postQuery,
      meta: {
        errorMessage: "Failed to execute query",
      },
      onSuccess: (data) => {
        setResults(data);
      },
      onError: (error) => {
        ToastrError("Error fetching results", error.message);
      },
    });

  // Call the API to load our query results whenever the query changes.
  useEffect(() => {
    executeQuery();
  }, [sanitizeQuery]); // eslint-disable-line

  const query = generateQueryBuilderSql(queryData);

  const schema = results?.results?.[0]?.schema;
  const rows = results?.results?.[0]?.rows;

  const tableColumns = useMemo(() => {
    if (schema == null) return [];

    return schema.map((x) => {
      return {
        accessorKey: x.columnLabel!,
        id: x.columnLabel!,
        enableSorting: true,
      };
    });
  }, [schema]);

  type ISanitizedResult = Record<string, string | number | boolean>;

  const sanitizedResults: ISanitizedResult[] = useMemo(() => {
    if (rows == null) {
      return [];
    }

    const allColumnNamesArray = tableColumns.map((x) => x.accessorKey);

    const resultArray: ISanitizedResult[] = [];
    for (let i = 0; i < rows.length; i++) {
      const row: Record<string, string | number | boolean> = {};
      for (let j = 0; j < allColumnNamesArray.length; j++) {
        row[allColumnNamesArray[j]] = rows[i][j];
      }
      resultArray.push(row);
    }

    return resultArray;
  }, [tableColumns, rows]);

  const executeQuery = async () => {
    ToastrClean();
    setResults(null);

    const sanitizeParameters: sanitizeQueryProps = {
      currentQueryInput: query,
      queryLimit: 10,
      setQueryLimit: () => {
        // no-op
      },
      setCurrentQueryInput: () => {
        // no-op
      },
      enforceLimit: true,
    };

    const sanitizedQuery = sanitizeQuery(sanitizeParameters);

    const postData = {
      query: sanitizedQuery,
      defaultCatalog: "",
    };

    await executeQueryAsync({
      isWorkspace: false,
      workspaceName: "",
      header: { "Connect-Cloud-Client": "CDataDataExplorer" },
      body: postData,
    });
  };

  return (
    <div className="queryBuilderPreviewTab">
      <ResultsDetailsGridContainer
        tab={tab}
        loading={isExecutePending}
        showResults={!isExecutePending && results != null}
        resultsToggled
        resultColumns={tableColumns}
        resultData={sanitizedResults}
        noData={() => (
          <div className="text-muted text-center">No matching records</div>
        )}
        columnDetails={[]}
        columnMetadata={[]}
        enablePagination={false}
        abortRunningQueryRequest={props.abortRunningQueryRequest}
      />
    </div>
  );
}
