import { cdataFetch } from "../../../api/cdataFetch";
import { IQueryResult } from "../../../models";
import { IServiceError } from "../../../models/IServiceError";

interface IExecuteQueryParams {
  isWorkspace: boolean;
  workspaceName: string;
  header: Record<string, string> | undefined;
  body: {
    query: string;
    defaultCatalog: string;
  };
  abortSignal?: AbortSignal;
}

export async function postQuery(params: IExecuteQueryParams) {
  const url = params.isWorkspace
    ? `/query?workspace=${params.workspaceName}`
    : "/query";

  const result = await cdataFetch<IQueryResult | IServiceError>({
    method: "POST",
    url: url,
    headers: params.header ?? {},
    body: JSON.stringify(params.body),
    abortSignal: params.abortSignal ?? undefined,
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result;
}
