import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { IDerivedView } from "../../../../models";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrSuccess } from "../../../../services/toastrService";
import { Spinner } from "reactstrap";
import { useMutation } from "@tanstack/react-query";
import { deleteDerivedView } from "../../api/deleteDerivedView";

export type DeleteDerivedViewModalProps = {
  displayed: boolean;
  close: () => void;
  fetchDerivedViews: () => void;
  targetDerivedView: IDerivedView | null;
  setTargetDerivedView: (newTargetDerivedView: IDerivedView | null) => void;
};

export function DeleteDerivedViewModal(props: DeleteDerivedViewModalProps) {
  const {
    displayed,
    close,
    fetchDerivedViews,
    targetDerivedView,
    setTargetDerivedView,
  } = props;

  const { mutateAsync: deleteDerivedViewAsync, isPending: isDeletePending } =
    useMutation({
      mutationKey: ["/account/derivedViews/delete"],
      mutationFn: deleteDerivedView,
      meta: {
        errorMessage:
          "Failed to delete derived view due to the following error:",
      },
      onSettled: () => {
        close();
      },
      onSuccess: () => {
        fetchDerivedViews();
        ToastrSuccess(
          "Derived View successfully deleted",
          `${targetDerivedView?.name} was successfully deleted.`,
        );
        setTargetDerivedView(null);
      },
    });

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={() => {
          setTargetDerivedView(null);
          close();
        }}
        title="Delete Derived View"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Danger}
            onClick={() => deleteDerivedViewAsync(targetDerivedView?.id ?? "")}
          >
            Delete
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={() => {
              setTargetDerivedView(null);
              close();
            }}
          >
            Cancel
          </CDataButton>
        }
      >
        You are about to delete the derived view, {targetDerivedView?.name}. By
        processing this request, any OData entity sets that originate from it
        will also be removed. Are you sure you want to proceed?
      </CDataModalV2>
      <div hidden={!isDeletePending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
