import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrSuccess } from "../../../../services/toastrService";
import { Spinner } from "reactstrap";
import { ISavedQuery } from "../../../../models/SavedQueries/ISavedQuery";
import { LeftPanelType } from "../leftPanelType";
import { useMutation } from "@tanstack/react-query";
import { deleteSavedQuery } from "../../api/deleteSavedQuery";

export type DeleteSavedQueryModalProps = {
  displayed: boolean;
  close: () => void;
  targetSavedQuery?: ISavedQuery;
  savedQueries: ISavedQuery[];
  fetchSavedQueries: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
};

export function DeleteSavedQueryModal(props: DeleteSavedQueryModalProps) {
  const {
    displayed,
    close,
    targetSavedQuery,
    savedQueries,
    fetchSavedQueries,
    setSidebarView,
  } = props;

  const { mutateAsync: deleteSavedQueryAsync, isPending: isDeletePending } =
    useMutation({
      mutationKey: ["/deleteSavedQuery"],
      mutationFn: deleteSavedQuery,
      meta: {
        errorMessage:
          "Failed to delete saved query due to the following error:",
      },
      onSettled: () => {
        close();
      },
      onSuccess: () => {
        const currSavedQueryCount = savedQueries.length;
        fetchSavedQueries();
        ToastrSuccess(
          "Saved Query successfully deleted",
          `${targetSavedQuery?.name} was successfully deleted.`,
        );
        if (currSavedQueryCount === 1) {
          //case where there are no more remaining saved queries, navigate back to connections
          setSidebarView(LeftPanelType.Connections);
        }
      },
    });

  if (!targetSavedQuery) {
    return null;
  }

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Delete Saved Query"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Danger}
            onClick={() => deleteSavedQueryAsync(targetSavedQuery?.id ?? "")}
          >
            Delete
          </CDataButton>
        }
        secondaryButton={
          <CDataButton buttonType={ButtonType.Secondary} onClick={close}>
            Cancel
          </CDataButton>
        }
      >
        You are about to delete the saved query, {targetSavedQuery.name}. Are
        you sure you want to proceed?
      </CDataModalV2>
      <div hidden={!isDeletePending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
