import { Col, Label, Row } from "reactstrap";
import { LimitControl } from "./LimitControl";
import { QueryEditorSaveButton } from "./QueryEditorSaveButton";
import { useState } from "react";
import { QueryTabType } from "../Tabs/queryTabType";
import { IQueryTab } from "../Tabs/useQueryTabs";
import { BillingStatus } from "../../../../models";
import { useAppSelector } from "../../../../redux/hooks";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { CDataTypography } from "src/components/text/CDataTypography";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";

interface IQueryEditorButtonBar {
  tab: IQueryTab;
  abortRunningQueryRequest: () => void;
  cancelButtonDisabled: boolean;
  queryInput: string;
  handleRunClick: React.MouseEventHandler<HTMLButtonElement>;
  handleRefreshClickCallback: React.MouseEventHandler<HTMLButtonElement>;
  queryLimit: number;
  setQueryLimit: (queryLimit: number) => void;
  wasQuerySuccessful: boolean;
  executeDisabled: boolean;
  setExecuteDisabled: (newExecuteDisabled: boolean) => void;
  areDerivedViewsAllowed: boolean;
  openSaveChangesModal: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
}

export const QueryEditorButtonBar = (props: IQueryEditorButtonBar) => {
  const {
    tab,
    queryInput,
    handleRunClick,
    abortRunningQueryRequest,
    handleRefreshClickCallback,
    queryLimit,
    setQueryLimit,
    wasQuerySuccessful,
    executeDisabled,
    areDerivedViewsAllowed,
    openSaveChangesModal,
  } = props;

  const usage = useAppSelector((state) => state.usage);

  const [createDropdownOpen, setCreateDropdownOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  //Dropdown needs more time to close in order for tooltip routing not to break.
  const toggleCreateDropdown = () => {
    setTimeout(() => {
      setCreateDropdownOpen(!createDropdownOpen);
    }, 100);
  };

  const trialOverage =
    usage.rowsOverLimit === true &&
    usage.billingStatus ===
      (BillingStatus.NewAccount ||
        BillingStatus.Trial ||
        BillingStatus.TrialEnding);

  const executeButtonTooltip = trialOverage
    ? "You cannot execute queries because you have reached your rows limit."
    : "After selecting a connection, select Query on a table, column, or view in order to execute the query.";

  const isSaveChangesVisible =
    tab.tabType === QueryTabType.DerivedView ||
    tab.tabType === QueryTabType.SavedQuery ||
    tab.tabType === QueryTabType.ScheduledQuery
      ? tab.unsavedChanges
      : false;

  return (
    <Row className="data-explorer-top-row align-items-center mx-0">
      <Col className="ps-0">
        <CDataTooltip
          type={CDataTooltipType.Dark}
          title={executeDisabled ? executeButtonTooltip : ""}
        >
          <span id="executeQueryButton">
            <CDataButton
              buttonType={ButtonType.Primary}
              data-testid="execute-button"
              onClick={handleRunClick}
              disabled={executeDisabled}
              className={executeDisabled ? "me-2 no-pointer-event" : "me-2"}
            >
              <i className="fa fa-solid fa-play me-2" />
              Execute
            </CDataButton>
          </span>
        </CDataTooltip>
        <CDataButton
          buttonType={ButtonType.Secondary}
          aria-label="cancel-button"
          onClick={() => abortRunningQueryRequest()}
          disabled={props.cancelButtonDisabled}
        >
          Cancel
        </CDataButton>
        <CDataButton
          buttonType={ButtonType.Borderless}
          color="#3f4b57"
          disabled={queryInput === ""}
          className="clear-button"
          onClick={(e: any) => handleRefreshClickCallback(e)}
          data-testid="button-clear"
        >
          <i className="fa-regular fa-xmark align-middle me-2"></i>
          <CDataTypography>Clear</CDataTypography>
        </CDataButton>
      </Col>
      <Col>
        <div className="float-end limit-container">
          <Label className="me-2">Limit:</Label>
          <LimitControl queryLimit={queryLimit} setQueryLimit={setQueryLimit} />
          <QueryEditorSaveButton
            tab={tab}
            areDerivedViewsAllowed={areDerivedViewsAllowed}
            connectionsOverLimit={usage.connectionsOverLimit!}
            createDropdownOpen={createDropdownOpen}
            openSaveChangesModal={openSaveChangesModal}
            isSaveChangesVisible={isSaveChangesVisible!}
            areSaveAsButtonsDisabled={executeDisabled || !wasQuerySuccessful}
            toggle={() => setTooltipOpen(!tooltipOpen)}
            toggleCreateDropdown={toggleCreateDropdown}
            tooltipOpen={tooltipOpen}
            usersOverLimit={usage.usersOverLimit!}
            queryText={queryInput}
            fetchDerivedViews={props.fetchDerivedViews}
            fetchSavedQueries={props.fetchSavedQueries}
            setSidebarView={props.setSidebarView}
          />
        </div>
      </Col>
    </Row>
  );
};
